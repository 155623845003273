import { Button, EasyModal } from '@ui';
import React, { useEffect, useState } from 'react';

import CompositeMetricBlock from '@app/pages/analytics/metric/blocks/metric-blocks/composite-metric.block';

import {
  MetricTypesArrType,
  AllChanelsArrType,
  MetricObjType,
  MetricListArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import AccordionUI from '@app/components/ui/Accordion/Accordion';

import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';

import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';

import Icon from '@app/components/ui/icons';

import { AllStatusProject } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { metricIconsData } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/data/data';

import {
  useGetMetricTemplateGroupsListQuery,
  useLazyGetSingleMetricTemplateQuery,
} from '@app/store/api/metric-template.api';

import { MetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';

import cn from 'classnames';

import ClassifierModalBlock from '@app/pages/analytics/metric/analytics-metric-tab/blocks/modals/classifier-metric-modal/classifier-modal-block';

import { ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';

import HandleMetricModalBlock from './modals/handle-metric-modal';

import { HoverButton } from './hoverButton';
import { MetaMetricModal } from './modals/meta-metric-modal';
import { SpeechModals } from './modals/speech-metric-modal';
import { VadModals } from './modals/vad-modals';
import { ChatModals } from './modals/chat-modals/chat-modal';
import { ChatFindWordsModal } from './modals/chat-modals/chat-find-words-metric-modal';
import NonFcrMetric from './modals/non-fcr-metric/non-fcr-metric';

type PropsType = {
  metricTypes?: MetricTypesArrType[];
  allChannels?: AllChanelsArrType[];
  editModal?: boolean;
  copyModal?: boolean;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  page?: string;
  setFirstModal: React.Dispatch<React.SetStateAction<boolean>>;
  firstModal: boolean;
  activeModal: string;
  setActiveModal: React.Dispatch<React.SetStateAction<string>>;
  status?: AllStatusProject | undefined;
  viewOnly?: boolean;
  metricSettings?: MetricObjType | undefined;
  metricSettingsLoading?: boolean;
  metricTemplateSettings?: MetricTemplatesType | undefined;
  metricList?: MetricListArrType[];
  chatRoles?: ChatRoleSettingsType[];
  currentTemplate: 'chat' | 'voice';
};

export const Modals: React.FC<PropsType> = ({
  metricTypes,
  allChannels,
  editModal,
  page,
  firstModal,
  setFirstModal,
  activeModal,
  setActiveModal,
  setEditModal,
  status,
  copyModal = false,
  viewOnly = false,
  chatRoles,
  metricSettings,
  metricTemplateSettings: metricTemplateSettingsData,
  metricSettingsLoading,
  metricList,
  currentTemplate,
}) => {
  // MODALS STATE
  const [createFromTemplate, setCreateFromTemplate] = useState<boolean>(false);
  const [createMetricModal, setCreateMetricModal] = useState<boolean>(false);
  const [metricTemplatesModal, setMetricTemplatesModal] = useState<boolean>(false);
  const [activeAccordionItem, setActiveAccordionItem] = useState<string | undefined>(undefined);
  const [metricTemplateSettings, changeMetricTemplateSettings] = useState(
    metricTemplateSettingsData,
  );
  const otherListModals = {
    calculated: CompositeMetricBlock,
    tags: HandleMetricModalBlock,
    classifier: ClassifierModalBlock,
  };
  const { t } = useTranslation('pages.metricTemplates');
  //api
  const { isLoading: metricsTemplateLoading, data: metricTemplates } =
    useGetMetricTemplateGroupsListQuery(currentTemplate);
  const [getMetricTemplateSettings] = useLazyGetSingleMetricTemplateQuery();

  useEffect(() => {
    changeMetricTemplateSettings(metricTemplateSettingsData);
  }, [metricTemplateSettingsData]);
  //modals data
  const SummSpeechDurationModal = [
    {
      inputText: [{ id: 'metricName', labelTitle: t('metric_name'), placeholder: t('input') }],
      radioButtons: [
        {
          labelTitle: t('summ_value'),
          radioBut: [
            { id: 'sec', value: 'sec', label: t('secs') },
            { id: 'proc', value: 'proc', label: '%' },
          ],
        },
      ],
      inputNumber: [
        { labelTitle: t('exclude_begin_sec'), id: 'exclude_begin_sec' },
        { labelTitle: t('exclude_end_sec'), id: 'exclude_end_sec' },
        { labelTitle: t('search_begin_sec'), id: 'search_begin_sec' },
        { labelTitle: t('search_end_sec'), id: 'search_end_sec' },
      ],
      inputNumberChat: [
        { labelTitle: t('exclude_begin_messages'), id: 'exclude_begin_messages' },
        { labelTitle: t('exclude_end_messages'), id: 'exclude_end_messages' },
        { labelTitle: t('search_begin_sec_c'), id: 'search_begin_messages' },
        { labelTitle: t('search_end_sec_c'), id: 'search_end_messages' },
      ],
    },
  ];
  const arrWithHelpersForMetrics = [
    {
      type: 'meta',
      text: t('meta'),
    },
    {
      type: 'summ-speech-duration',
      text: t('summ_speech_duration'),
    },
    {
      type: 'summ-silent-duration',
      text: t('summ_silent_duration'),
    },
    {
      type: 'interruptions',
      text: t('interruptions'),
    },
    {
      type: 'pause-exceed',
      text: t('pause_exceed'),
    },
    {
      type: 'avg-pause-duration',
      text: t('avg_pause_duration'),
    },
    {
      type: 'max-pause-duration',
      text: t('max_pause_duration'),
    },
    {
      type: 'find-words',
      text: t('find_words'),
    },
    {
      type: 'max-monolog-duration',
      text: t('max_monolog_duration'),
    },
    {
      type: 'speech-rate-duration',
      text: t('speech_rate_duration'),
    },
    {
      type: 'calculated',
      text: t('calculated'),
    },
    {
      type: 'tags',
      text: t('tags_metric'),
    },
    {
      type: 'c_longest-pause-duration',
      text: t('metric_template'),
    },
    {
      type: 'c_find-words',
      text: t('find_words'),
    },
    {
      type: 'c_first-answer-duration',
      text: t('c_first_answer_duration'),
    },
    {
      type: 'c_avg-pause-duration',
      text: t('c_avg_pause_duration'),
    },
    {
      type: 'c_spell-check',
      text: t('c_spell_check'),
    },
    {
      type: 'c_num-words-message',
      text: t('c_num_words_message'),
    },
    {
      type: 'c_characters',
      text: t('c_characters'),
    },
    {
      type: 'c_pause-exceed',
      text: t('c_pause_exceed'),
    },
    {
      type: 'nonfcr',
      text: t('nonfcr'),
    },
  ];

  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    if (event.id === activeAccordionItem) {
      if (event.id === '') {
        setActiveAccordionItem(undefined);
      } else {
        setActiveAccordionItem('');
      }
    } else {
      setActiveAccordionItem(event.id);
    }
  };

  // MODALS functions
  const createNewMetricClick = () => {
    setFirstModal(false);
    setCreateMetricModal(true);
  };
  const metricTemplateClick = () => {
    setFirstModal(false);
    setMetricTemplatesModal(true);
  };
  const onModalTypeClick = (type: string) => {
    setCreateMetricModal(false);
    setFirstModal(false);
    setActiveModal(type);
  };
  const onCreateMetricFromTemplate = (type: string, metric_template_id: string) => {
    setCreateFromTemplate(true);
    setCreateMetricModal(false);
    setMetricTemplatesModal(false);
    getMetricTemplateSettings({ id: metric_template_id }).then((data) => {
      changeMetricTemplateSettings(data.data);
      setActiveModal(type);
    });
  };
  const modalsSetState = (type?: string) => {
    if (type === activeModal) {
      setActiveModal('');
      setEditModal(!editModal);
      if (createFromTemplate) {
        setCreateFromTemplate(!createFromTemplate);
      }
    }
  };
  // MODALS functions
  const metricTypesArr = () => {
    if (!metricTypes) return [];
    const formattedMetricTypes: MetricTypesArrType[] = [];
    const exclude_types: MetricTypesArrType[] = [];
    for (let i = 0; i < metricTypes.length; i++) {
      if (
        metricTypes[i].type === 'classifier' ||
        metricTypes[i].type === 'avg-conflict' ||
        metricTypes[i].type === 'max-conflict' ||
        metricTypes[i].type === 'nonfcr'
      ) {
        exclude_types.push(metricTypes[i]);
        continue;
      }
      formattedMetricTypes.push(metricTypes[i]);
    }
    const metricTypesResult = [...formattedMetricTypes, ...exclude_types];
    const defaultYTypes =
      process.env.REACT_APP_THEME === 'rtk'
        ? metricTypesResult?.filter(
            (metric) =>
              metric.type !== 'classifier' &&
              metric.type !== 'max-conflict' &&
              metric.type !== 'avg-conflict',
          )
        : metricTypesResult;
    if (page === 'metricTemplate') {
      return defaultYTypes?.filter((type) => type.group !== 'meta' && type.type !== 'calculated');
    } else return defaultYTypes;
  };

  const helperFunc = (type: string) => {
    return (
      <HoverButton
        helperText={arrWithHelpersForMetrics.find((item) => item.type === type)?.text || 'empty'}
      />
    );
  };
  const modalLabelRender = (metric: MetricTypesArrType) => {
    const icon = metric.type.replace(/([.\-\\/ ])/g, '_');
    const isCalculated = metric.type === 'calculated';
    return (
      <div
        className={cn(
          isCalculated
            ? 'flex-row w-fit gap-[10px]'
            : 'flex-col mb-[25px] gap-[20px] w-full justify-center',
          'flex items-center',
        )}
      >
        <Icon size={isCalculated ? 28 : 57} name={metricIconsData[icon] || 'HelpCircleIcon'} />
        <div className="w-full text-center">
          {metric.name}
          {helperFunc(metric.type)}
        </div>
      </div>
    );
  };
  const switchModalVariant = (type: string) => {
    switch (type) {
      case 'find-words':
        return 'largeW1200';
      case 'c_find-words':
        return 'largeW1200';
      case 'calculated':
        return 'largeW1127';
      default:
        return 'mediumW650';
    }
  };

  const findWordsMetricsOptions = metricList
    ?.filter((metric) => metric.type === 'find-words' || metric.type === 'c_find-words')
    .map(({ metric_id, name }) => ({ title: name, value: metric_id }));

  const modalMetricComponents = {};
  const modalListData = {};
  metricTypes?.forEach((metricItem) => {
    modalListData[metricItem.type] = {
      easyModal: {
        label: modalLabelRender(metricItem),
        show: activeModal === metricItem.type,
        onClose: () => modalsSetState(metricItem.type),
        withoutFooter: metricItem.type === 'nonfcr' ? true : !(metricItem.group === 'other'),
        variant: switchModalVariant(metricItem.type),
      },
      metricChildren: {
        copyModal,
        currentTemplate,
        page,
        status,
        findWordsMetricsOptions,
        createFromTemplate: page === 'metricTemplate' ? false : createFromTemplate,
        unitVal: activeModal,
        metricTemplate: page === 'metricTemplate',
        modalsSetState,
        metric: metricItem,
        dataFields: SummSpeechDurationModal,
        editModal,
        allChannels,
        chatRoles,
        viewOnly,
        metricSettings,
        metricTemplateSettings,
        metricList,
      },
    };
    modalMetricComponents[metricItem.type] = modalMetricComponents[metricItem.group] =
      metricItem.group === 'meta'
        ? MetaMetricModal
        : metricItem.group === 'vad'
        ? VadModals
        : metricItem.group === 'speech'
        ? SpeechModals
        : metricItem.group === 'chat'
        ? metricItem.type === 'c_find-words'
          ? ChatFindWordsModal
          : ChatModals
        : metricItem.type === 'nonfcr'
        ? NonFcrMetric
        : otherListModals[metricItem.type];
  });
  const ModalElem = modalMetricComponents[activeModal];

  const renderModal = () =>
    activeModal &&
    (Object.keys(otherListModals).includes(activeModal) ? (
      metricSettingsLoading ? (
        <></>
      ) : (
        <ModalElem
          {...modalListData[activeModal]?.metricChildren}
          {...modalListData[activeModal]?.easyModal}
        />
      )
    ) : metricsTemplateLoading ? (
      <></>
    ) : (
      <EasyModal {...modalListData[activeModal]?.easyModal}>
        <ModalElem {...modalListData[activeModal]?.metricChildren} />
      </EasyModal>
    ));

  return (
    <>
      {/* modalWindows */}
      {page !== 'metricTemplate' && (
        <EasyModal
          show={firstModal}
          onClose={() => setFirstModal(false)}
          variant="small"
          label={<div className="flex items-center justify-center">{t('metric_create')}</div>}
          withoutFooter
        >
          <div className="flex flex-col w-full p-6 gap-[20px] items-center justify-center">
            <Button
              onClick={() => createNewMetricClick()}
              className="w-[300px] justify-center"
              label={t('metric_create_new')}
            />
            <Button
              onClick={() => metricTemplateClick()}
              className="w-[300px] justify-center"
              label={t('from_template')}
            />
          </div>
        </EasyModal>
      )}
      {page !== 'metricTemplate' && (
        <EasyModal
          show={metricTemplatesModal}
          onClose={() => setMetricTemplatesModal(false)}
          label={<div className="">{t('from_template_add')}</div>}
          variant="mediumW650"
          withoutFooter
        >
          <div className="w-full">
            <AccordionUI>
              {metricTemplates?.map((group) => {
                return (
                  <AccordionItemUI
                    dataId={`ap-metric-group-${group.name}`}
                    key={group.metric_templates_group_id}
                    id={group.metric_templates_group_id}
                    title={group.name}
                    clickBtnChild={clickAccordionHeaderBtn}
                    active={activeAccordionItem === group.metric_templates_group_id}
                  >
                    {activeAccordionItem === group.metric_templates_group_id && (
                      <ul className="flex w-full rounded-[12px] flex-col border-[1px] border-basic_app_bg">
                        {group.metric_templates?.map((type) => {
                          return (
                            <li
                              data-id={`ap-metric-group-${group.name}-${type.name}`}
                              className="w-full items-center justify-between flex border-b-[1px] border-basic_app_bg p-[10px] text-1color text-[17px] h-[64px]"
                              key={type.metric_template_id}
                            >
                              <button
                                onClick={() =>
                                  onCreateMetricFromTemplate(type.type, type.metric_template_id)
                                }
                                className={`flex w-full items-center justify-between`}
                              >
                                {type.name}
                                <span className="w-[28px] text-[#1C1C1E] bold">&gt;</span>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </AccordionItemUI>
                );
              })}
            </AccordionUI>
          </div>
        </EasyModal>
      )}
      <EasyModal
        show={page !== 'metricTemplate' ? createMetricModal : firstModal}
        onClose={
          page !== 'metricTemplate' ? () => setCreateMetricModal(false) : () => setFirstModal(false)
        }
        withoutFooter
        variant="mediumW650"
        label={page === 'metricTemplate' ? t('add_new_template') : t('add_new_metric')}
      >
        <ul
          style={{ paddingBottom: '10px' }}
          className="min-w-full flex flex-col items-end rounded-[12px] border-[1px] border-defaultBg"
        >
          {metricTypesArr()?.map((type) => {
            return (
              <li
                data-id={`ap-metric-type-${type.name}`}
                className={`${
                  (type.type === 'max-conflict' || type.type === 'avg-conflict') &&
                  'pointer-events-none'
                } w-full flex border-b-[1px] border-defaultBg p-[10px] text-1color text-[17px] h-[64px]`}
                key={type.type}
              >
                <Icon
                  size={40}
                  className="mr-[10px]"
                  name={metricIconsData[type.type.replace(/([.\-\\/ ])/g, '_')] || 'HelpCircleIcon'}
                />
                <button
                  onClick={() => onModalTypeClick(type.type)}
                  className={`flex w-full items-center justify-between`}
                >
                  <div
                    className={`flex items-center ${
                      (type.type === 'max-conflict' || type.type === 'avg-conflict') &&
                      'text-4color'
                    }`}
                  >
                    {type.name}
                    {(type.type === 'max-conflict' || type.type === 'avg-conflict') && (
                      <div className="self-start text-[9px] text-4color ml-2">(Скоро)</div>
                    )}
                  </div>
                  <span className="w-[28px] text-[#1C1C1E] bold">&gt;</span>
                </button>
              </li>
            );
          })}
        </ul>
      </EasyModal>
      {renderModal()}
    </>
  );
};
