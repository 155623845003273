import React, { useEffect, useMemo, useState } from 'react';

import { RecordActionsDropdown } from '@app/interfaces/records';

import useTranslation from '@app/hooks/use-translation';
import {
  Button,
  EasyFilterView,
  EasyModal,
  Loading,
  PageHeader,
  PermissionAccessWrapper,
  Popover,
} from '@ui';
import { PopoverMenuItem } from '@app/components/ui/popover/popover.type';
import { userPermissions } from '@app/utils/user-permissions';
import ManualRecordingUploadPage from '@app/pages/settings/manual-recording-upload.page';
import RecordsTableBlock from '@app/pages/records/blocks/records-table/records-table.block';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import { GetFilterParamsNew, Filter, Range } from '@app/components/ui/easy-filter/types';
import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import {
  useCreateSinglePresetMutation,
  useGetPresetTemplateGroupsListQuery,
} from '@app/store/api/preset-template.api';
import { useNavigate } from 'react-router-dom';
import { getRecordAudio } from '@app/api/record.api';
import fileDownload from 'js-file-download';
import {
  useDeleteRecordsByFilterMutation,
  useDeleteRecordsMutation,
} from '@app/store/api/records.api';
import { ChangeColumnDisplayModalBlock } from '@app/pages/records/blocks/change-column-display-modal.block';
import { useAppSelector } from '@app/store/store';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';
import { useGetExtAndUsrModelsQuery } from '@app/store/api/asr_model.api';
import {
  FullTextSearchRequestType,
  FullTextSearchResponseType,
} from '@app/interfaces/full-text-search.type';
import { useLazyGetFullWordSearchQuery } from '@app/store/api/get-full-text-search.api';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import { Oscilogram } from '@app/components/ui/icons/icons-list';
import { FilterSearchTable } from '@app/components/filter-search-table';
import { useGetRecognitionDefaultModelQuery } from '@app/store/api/recognition-rules-settings-page.api';

import { TableFilter } from '@app/components/table/table.type';

import { useGetRtkBlockStatusQuery } from '@app/store/api/rtk-block.api';

import DeleteByFilterModal from './blocks/delete-by-filter-modal';

const DEFAULT_TABLE_LIMIT = 10;

const RecordsPage: React.FC = () => {
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const navigate = useNavigate();
  const DEFAULT_FILTER_SETUP: GetFilterParamsNew = {
    offset: 0,
    limit: userSettings?.tablesLimit?.allRecords || DEFAULT_TABLE_LIMIT,
    preset_mode: false,
    filter: {
      range: {
        type: 'n',
        parameters: [],
      },
      filter: [],
    },
  };
  const { t } = useTranslation('pages.allRecords');
  const { t: filterSearchT } = useTranslation('components.filterSearch');

  const { data, isLoading } = useGetAliasesListQuery();
  // rtk block api
  const { data: rtkBlockData } = useGetRtkBlockStatusQuery();
  // rtk block api
  // data for search
  const { data: default_model, isLoading: defaultModelLoad } = useGetRecognitionDefaultModelQuery();
  const [fullWordTextSearchRequestData, setFullWordTextSearchRequestData] = useState<
    Partial<FullTextSearchRequestType>
  >({ offset: 0, limit: 10 });
  const [fullWordSearchTable, setFullWordSearchTable] = useState<FullTextSearchResponseType>();
  const [getFullTextTableData, { isLoading: searchLoading }] = useLazyGetFullWordSearchQuery();
  const { data: channels, isLoading: channelsLoading } = useGetChannelsQuery();
  const { data: models, isLoading: modelsLoading } = useGetExtAndUsrModelsQuery();

  // data for search
  const [recordsParams, getRecordsParams] = useState<GetFilterParamsNew>(DEFAULT_FILTER_SETUP);
  const [selectedRecords, changeSelectedRecords] = useState<string[]>([]);
  const [columnDisplayFormModalValue, setColumnDisplayFormModalValue] = useState(false);
  const [deleteSelectedRecords, changeDeleteSelectedRecordsModalState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  //api
  const { data: presetTemplates } = useGetPresetTemplateGroupsListQuery();
  const [createPreset] = useCreateSinglePresetMutation();
  const [deleteRecordRequest] = useDeleteRecordsMutation();
  //remove by filter
  const [removeAllRecordsModalState, changeRemoveAllRecordsModalState] = useState(false);
  const [deleteRecordByFilterRequest] = useDeleteRecordsByFilterMutation();
  //remove by filter

  useEffect(() => {
    userSettings?.tablesLimit?.allRecords &&
      getRecordsParams((prev) => ({
        ...prev,
        limit: userSettings?.tablesLimit?.allRecords as number,
      }));
  }, [userSettings?.tablesLimit?.allRecords]);

  const actionsDropdown: PopoverMenuItem[] = [
    {
      title: t('popover.download_records'),
      key: RecordActionsDropdown.DOWNLOAD_RECORDS,
      icon: 'DownloadCloudIcon',
      disabled: !selectedRecords.length,
    },
    {
      title: t('popover.delete_selected'),
      key: RecordActionsDropdown.DELETE_SELECTED,
      icon: 'TrashIcon',
      color: 'danger',
      disabled: !selectedRecords.length,
      permission: { tag: userPermissions.action.actionDeleteRecords, permissions: 'action' },
    },
    {
      title: t('popover.delete_by_filter'),
      key: RecordActionsDropdown.DELETE_BY_FILTER,
      icon: 'TrashIcon',
      color: 'danger',
      // TODO: Permissions
      // disabled: !selectedRecords.length,
      // permission: { tag: userPermissions.action.actionDeleteRecords, permissions: 'action' },
    },
  ];

  // full text search

  function onSubmitSearch(data) {
    const combinedData = { ...fullWordTextSearchRequestData, ...data };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }

  function handleChangePage(type: 'prev' | 'next') {
    switch (type) {
      case 'next':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;
      case 'prev':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;

      default:
        break;
    }
  }

  function getFullSearchTable(data: Partial<FullTextSearchRequestType>) {
    if (!data) return;
    getFullTextTableData({
      ...data,
      asr_model_id: data.asr_model_id === 'all' ? undefined : data.asr_model_id,
      channel: data.channel === 'all' ? undefined : data.channel,
    })
      .unwrap()
      .then((data) => setFullWordSearchTable(data));
  }
  const tableFullTextSearchColumns: TableColumn[] = [
    { index: 'date', title: filterSearchT('table.date'), maxWidth: 240, filter: true },
    { index: 'text', title: filterSearchT('table.text') },
    { index: 'channel', title: filterSearchT('table.channel'), maxWidth: 120 },
    { index: 'asr_model_id', title: filterSearchT('table.model'), maxWidth: 120 },
    { index: 'actions', title: filterSearchT('table.actions'), maxWidth: 90 },
  ];
  const tableFullTextSearchData = (fullWordSearchTable?.results || []).map((tableItem) => ({
    ...tableItem,
    date: tableItem.record.dt_start,
    text: <div dangerouslySetInnerHTML={{ __html: tableItem.text }} title={tableItem.text} />,
    channel: tableItem.channel.name,
    asr_model_id: models?.find((model) => model.model_id === tableItem.asr_model_id)?.name,
    actions: (
      <div className="flex w-full items-center justify-center">
        <Oscilogram
          hintTitle={filterSearchT('popup_hints.to_record_title')}
          size={22}
          className="text-3color  hover:text-action cursor-pointer transition"
          onClick={() =>
            window.open(`/${navigationRoutes.records}/${tableItem.record.record_id}`, '_blank')
          }
        />
      </div>
    ),
  })) as unknown as TableItem[];

  function handleResetSearch() {
    setFullWordSearchTable(undefined);
  }

  const tableSortKeyList = useMemo(
    () => ({
      date: 'datetime',
    }),
    [],
  );

  function tableSearchChangeFilterHandler(reportTableOrderBy: TableFilter) {
    const sort =
      reportTableOrderBy && Object.keys(reportTableOrderBy).length
        ? {
            sortBy: tableSortKeyList[Object.keys(reportTableOrderBy)[0]],
            sortDesc: 0 < Object.values(reportTableOrderBy)[0],
          }
        : { sortBy: undefined, sortDesc: undefined };

    const changedObject = {
      ...fullWordTextSearchRequestData,
      ...sort,
    } as Partial<FullTextSearchRequestType>;
    setFullWordTextSearchRequestData(changedObject);
    getFullSearchTable(changedObject);
  }

  // full text search

  const clickActionsDropdown = (key: string) => {
    if (key === RecordActionsDropdown.DOWNLOAD_RECORDS) {
      for (const record_id of selectedRecords) {
        downloadAudioHandler(record_id);
      }
    } else if (key === RecordActionsDropdown.DELETE_SELECTED) {
      changeDeleteSelectedRecordsModalState(true);
    } else if (key === RecordActionsDropdown.DELETE_BY_FILTER) {
      changeRemoveAllRecordsModalState(true);
    }
  };
  function removeRecords() {
    deleteRecordRequest({ items: selectedRecords }).then(() => changeSelectedRecords([]));
  }

  const changeColumnDisplayFormModalState = () => {
    setColumnDisplayFormModalValue(true);
  };
  async function downloadAudioHandler(record_id: string) {
    return await getRecordAudio({ id: record_id }).then((res) =>
      fileDownload(
        res.data,
        res.headers['content-disposition'].replace(/"/gim, '').replace('attachment; filename=', ''),
      ),
    );
  }
  function saveAsTemplatePreset() {
    if (!presetTemplates) return;
    const newPreset: PresetsCreateDataType = {
      name: t('system.preset_template_default_name'),
      description: '',
      preset_group_id:
        presetTemplates?.find((group) => group.is_default)?.preset_group_id ||
        presetTemplates?.[0]?.preset_group_id,
      shared: false,
      rules: recordsParams?.filter as Filter & Range,
    };
    createPreset(newPreset).then((data) => {
      const { data: presetData } = data as unknown as { data: PresetsDataType };
      navigate(`/${navigationRoutes.presets}/${presetData.preset_id}`);
    });
  }

  const getFooterFilter = (
    <div className="flex">
      <div className="grow">
        <Button
          data-id="save-as-new-preset"
          fill="linked"
          label={t('filter.buttons.save_as_template')}
          className="mr-[-22px]"
          onClick={() => saveAsTemplatePreset()}
          disabled={!recordsParams.filter.filter.length}
        />
        <Button
          fill="linked"
          label={t('filter.buttons.reset_filter')}
          disabled={
            !recordsParams.filter.filter.length && !recordsParams.filter.range.parameters.length
          }
          onClick={() => getRecordsParams(DEFAULT_FILTER_SETUP)}
        />
      </div>
    </div>
  );

  function getFilteringKeys() {
    return data?.aliases
      .filter((item) => !item.forbidden_rules)
      .map((alias) => ({
        id: alias.alias_id,
        title: alias.name,
        type_meta: alias.type_meta,
        type_value: alias.type_value,
      }));
  }
  function onChangeFilterHandler(data: Filter & Range) {
    getRecordsParams((prev) => ({ ...prev, filter: data }));
    if (!fullWordSearchTable) return;
    const combinedData = { ...fullWordTextSearchRequestData, filter: data };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }

  //delete all records handlers
  function onCloseFirstModal() {
    changeRemoveAllRecordsModalState(false);
  }

  function onRemoveByFilter() {
    deleteRecordByFilterRequest(recordsParams.filter);
  }

  //delete all records handlers

  return (
    <div>
      <PageHeader label={t('pageTitle')} icon="PlayCircleIcon">
        <div className="flex items-center gap-[15px]">
          <PermissionAccessWrapper
            tag={userPermissions.action.actionUploadRecord}
            permissions={'action'}
          >
            <Button
              fill="outlined"
              hintTitle={t('popup_hints.upload_modal')}
              icon="PlusIcon"
              onClick={openModal}
            />
          </PermissionAccessWrapper>
          <Popover
            size={270}
            label={t('popover.buttonLabel')}
            menu={actionsDropdown}
            position="end"
            onClick={(key) => clickActionsDropdown(key)}
          />
          <Button
            hintTitlePosition="left"
            hintTitle={t('popup_hints.data_display_set_up')}
            icon="ColumnDisplayIcon"
            onClick={() => changeColumnDisplayFormModalState()}
          />
        </div>
      </PageHeader>
      {!defaultModelLoad && (
        <EasyFilterView
          dataIdStartDate="all-records-date-from"
          dataIdEndDate="all-records-date-to"
          isLoading={isLoading || channelsLoading || modelsLoading || defaultModelLoad}
          footerMarkup={getFooterFilter}
          onChangeHandler={onChangeFilterHandler}
          keysForFiltering={getFilteringKeys() || []}
          fillFromTemplate
          data={recordsParams.filter}
          filterWithSearch
          handleSubmitSearchWithFilter={onSubmitSearch}
          handleResetSearch={handleResetSearch}
          selects={[
            {
              index: 'channel',
              width: 130,
              defaultValue: 'all',
              options: [
                { title: 'Все каналы', value: 'all' },
                ...(channels?.map((channel) => ({
                  title: channel.name,
                  value: channel.settings_channels_id,
                })) || []),
              ],
            },
            {
              index: 'asr_model_id',
              width: 130,
              defaultValue: default_model?.model_id || 'all',
              options: [
                { title: 'Все модели', value: 'all' },
                ...(models?.map((model) => ({
                  title: model.name,
                  value: model.model_id,
                })) || []),
              ],
            },
          ]}
        />
      )}
      {searchLoading ? (
        <Loading />
      ) : (
        <>
          {fullWordSearchTable && (
            <FilterSearchTable
              tableData={tableFullTextSearchData}
              tableCols={tableFullTextSearchColumns}
              handleChangePage={handleChangePage}
              offset={fullWordTextSearchRequestData?.offset || 0}
              limit={fullWordTextSearchRequestData.limit || 10}
              onFilter={tableSearchChangeFilterHandler}
            />
          )}
          <RecordsTableBlock
            changeSelectedRecords={changeSelectedRecords}
            selectedRecords={selectedRecords}
            recordsParams={recordsParams}
            getRecordsParams={getRecordsParams}
            hide={!!fullWordSearchTable}
          />
        </>
      )}
      <ChangeColumnDisplayModalBlock
        value={columnDisplayFormModalValue}
        recordsParams={recordsParams}
        setValue={setColumnDisplayFormModalValue}
      />
      {/*  delete rec modal*/}
      <EasyModal
        show={deleteSelectedRecords}
        onClose={changeDeleteSelectedRecordsModalState.bind(null, false)}
        label={
          <div className="flex items-center justify-center gap-[5px]">
            <div>{t('multi_delete_records_modal_label')}</div>
            <span className="text-basic_red">
              {selectedRecords.length} {t('records_for_delete_modal')}
            </span>
          </div>
        }
        onRemove={removeRecords}
        variant="removal"
        withoutFooter={true}
      >
        <div className="flex items-center justify-center w-full">
          {t('multi_delete_records_modal_description')}
        </div>
      </EasyModal>
      <div className="w-full">
        {isModalOpen && (
          <EasyModal show={isModalOpen} onClose={closeModal} variant="largeW1200">
            <ManualRecordingUploadPage
              rtkBlock={rtkBlockData?.rtk_block}
              onCloseModal={closeModal}
            />
          </EasyModal>
        )}
      </div>
      {/*  delete rec modal*/}
      {/*  delete rec modal FILTER*/}
      <DeleteByFilterModal
        showFirstModal={removeAllRecordsModalState}
        onCloseFirstModal={onCloseFirstModal}
        onConfirmRemove={onRemoveByFilter}
      />
      {/*  delete rec modal FILTER*/}
    </div>
  );
};

export default RecordsPage;
