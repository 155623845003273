module.exports = {
  project_records: 'Записи проекта',
  records: 'Записи',
  recordsId: 'Аудиозапись',
  chat_recordsId: 'Запись чата',
  analytic_project: 'Проект',
  analytics: 'Аналитические проекты',
  template: 'Шаблоны',
  reports: 'Отчеты',
  report: 'Отчет',
  dictionaries: 'Словари',
  dictionariesCreate: 'Добавление словаря',
  report_settings: 'Настройки отчета',
  report_list: 'Записи отчета',
  settings: 'Настройки',
  preset_templates: 'Шаблоны пресетов',
  preset_template: 'Пресет',
  metric_templates: 'Шаблоны метрик',
  wordsCloud: 'Облако слов',
  wordsNGram: 'Облако фраз',
  metrics: "Метрики",
  triggers: "Тригеры",
  ap_settings: 'Основные настройки',
  all_chats: "Все чаты",
  chat_analytic: "Аналитика чатов",
  chat_metric_templates: "Шаблоны метрик чатов",
};
