import React, { useEffect, useMemo, useState } from 'react';

import { Button, CircleButton, EasyFilterView, EasyModal, PermissionAccessWrapper } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useOutletContext, useParams } from 'react-router-dom';
import { GroupSharedStatus } from '@app/interfaces/analytics';

import { useEditLiveReportMutation } from '@app/store/api/live-reports.api';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import {
  useAddRecipientToEmailReportMutation,
  useAddRecipientToTgReportMutation,
  useCreateNewEmailRecipientReportMutation,
  useCreateNewTelegramRecipientReportMutation,
  useDeleteRecipientFromEmailReportMutation,
  useDeleteRecipientFromTgReportMutation,
  useDeleteReportTasksMutation,
  useGetReportTasksQuery,
  useLazyGetReportRecipientsBulkQuery,
  useLazyViewReportTasksQuery,
} from '@app/store/api/report_recipients.api';

import RubberTable from '@app/components/rubber-table';

import { TableColumn } from '@app/components/rubber-table/table.types';

import { ReportTaskObjectType } from '@app/interfaces/report-tasks';

import { useGetTenantRecipientsTgAndEmailQuery } from '@app/store/api/tenant-recipients.api';

import { AddRecipientsModal } from '@app/components';

import {
  MixedTriggerRecipientsType,
  TriggerPlatformType,
} from '@app/interfaces/metric-trigger.type';

import { toast } from 'react-toastify';

import { MixedRecipientsRequestType } from '@app/interfaces/tenant-recipients.type';

import { usePermissionCheck } from '@app/hooks/use-permission';

import { userPermissions } from '@app/utils/user-permissions';

import { useEditChatLiveReportMutation } from '@app/store/api/chat-api/live-reports.api';

import ReportTaskModalForm from './blocks/report-settings-blocks/report-task-modal-form';
import { ReportOutletContext } from './report.outlet';
const emptyFilter: Filter & Range = {
  range: {
    type: 'n',
    parameters: [],
  },
  filter: [],
};
const ReportSettingsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages.reports');
  const { t: err } = useTranslation('pages.triggers.errors');
  const { t: reportTaskTranslation } = useTranslation('pages.reports.report_task');
  const [filterParams, changeFilterParams] = useState<MixedRecipientsRequestType>({
    telegram: { offset: 0, limit: 10, confirmed: 'confirmed' },
    email: { offset: 0, limit: 10, confirmed: 'confirmed' },
  });
  const { metricList, liveReport, currentTemplate } = useOutletContext<ReportOutletContext>();
  const reportCanBeEdited = usePermissionCheck({
    tag: userPermissions.action.actionEditLiveReport,
    permissions: 'action',
  });
  const liveReportTaskPermission = usePermissionCheck({
    tag: userPermissions.display.displayLiveReportsTasks,
    permissions: 'display',
  });

  // api

  const [editLiveReportChat] = useEditChatLiveReportMutation();
  const [editLiveReportVoice] = useEditLiveReportMutation();

  const editLiveReport = { chat: editLiveReportChat, voice: editLiveReportVoice };
  // recipients logic
  const [deleteReportTask] = useDeleteReportTasksMutation();
  const { data: reportTaskList } = useGetReportTasksQuery(
    { live_report_id: id || '' },
    { skip: !!liveReportTaskPermission || !id },
  );
  const [viewReportTaskData] = useLazyViewReportTasksQuery();
  const { data: tenantRecipients, refetch } = useGetTenantRecipientsTgAndEmailQuery(filterParams, {
    skip: !!liveReportTaskPermission,
  });
  const [getReportRecipientsBulk] = useLazyGetReportRecipientsBulkQuery();
  const [createNewEmailRecipient] = useCreateNewEmailRecipientReportMutation();
  const [createNewTelegramRecipient] = useCreateNewTelegramRecipientReportMutation();

  const [addEmailRecipientToReport] = useAddRecipientToEmailReportMutation();
  const [deleteEmailRecipientFromReport] = useDeleteRecipientFromEmailReportMutation();
  const [addTgRecipientToReport] = useAddRecipientToTgReportMutation();
  const [deleteTgRecipientFromReport] = useDeleteRecipientFromTgReportMutation();
  // recipients logic
  // useState
  const [reportSubscribedRecipients, changeReportSubscribedRecipients] =
    useState<MixedTriggerRecipientsType>({
      telegram: [],
      email: [],
    });
  const [addRecipientReportId, changeAddRecipientReportId] = useState<string>();
  const [newFilterValues, changeNewFilterValues] = useState<Filter & Range>();
  const [activeReportTask, changeActiveReportTask] = useState<string>();
  const [reportTaskIdForDelete, changeTaskForDelete] = useState<string>();
  const [reportTaskModalState, changeTaskModalState] = useState(false);
  const [modalEditMode, changeModalEditMode] = useState(false);
  const [reportTaskObject, changeReportTaskObject] = useState<ReportTaskObjectType>();
  useEffect(() => {
    if (!liveReport) return;
    changeNewFilterValues(liveReport?.filter);
  }, [liveReport]);
  const report_cant_be_edited =
    !liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW;

  // handlers
  function onChangeFilterHandler(data: Filter & Range) {
    changeNewFilterValues(data);
  }
  const filteringKeys = useMemo(() => {
    return metricList?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }, [metricList]);

  function handleSaveFilter() {
    if (!liveReport || !newFilterValues) return;
    editLiveReport[currentTemplate || 'voice']({
      params: { id: liveReport.live_report_id },
      body: { name: liveReport.name, filter: newFilterValues },
    });
  }
  function saveButtonDisabled() {
    if (!liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW)
      return true;
    if (liveReport?.filter === newFilterValues) return true;
    // if (!newFilterValues?.filter.length) return true;
  }
  function resetButtonDisabled() {
    if (!liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW)
      return true;
    // if (!newFilterValues?.filter.length) return true;
    if (liveReport?.filter === newFilterValues) return true;
  }
  function deleteButtonDisabled() {
    if (!liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW)
      return true;
    if (!liveReport?.filter?.filter.length) return true;
    if (newFilterValues?.filter.length === 0) return true;
  }
  function handleResetFilter() {
    changeNewFilterValues(liveReport?.filter);
  }
  function handleDeleteFilter() {
    changeNewFilterValues(emptyFilter);
  }
  function handleRecipients(trigger_id: string) {
    refetch();
    handleGetSubscribedRecipients(trigger_id);
  }
  function handleGetSubscribedRecipients(report_task_id: string) {
    getReportRecipientsBulk({ report_task_id })
      .unwrap()
      .then((data) => {
        changeAddRecipientReportId(report_task_id);
        changeReportSubscribedRecipients(data);
      });
  }
  // jsx markup
  const titleMarkup = <h2 className="text-1color font-bold">{t('filter_settings')}</h2>;
  const footerMarkup = (
    <div className="w-full flex justify-between">
      <div>
        <Button
          onClick={handleSaveFilter}
          disabled={(() => {
            if (!reportCanBeEdited) {
              return saveButtonDisabled();
            } else return true;
          })()}
          label={t('button_settings.save')}
        />
        <Button
          fill="linked"
          variant="primary"
          label={t('button_settings.cancel')}
          onClick={handleResetFilter}
          disabled={resetButtonDisabled()}
        />
      </div>
      <div>
        <Button
          icon="TrashIcon"
          fill="linked"
          variant="danger"
          onClick={handleDeleteFilter}
          label={t('button_settings.remove')}
          disabled={deleteButtonDisabled()}
        />
      </div>
    </div>
  );

  const tableColumns: TableColumn[] = [
    { index: 'subject', title: reportTaskTranslation('fields.subject') },
    { index: 'description', title: reportTaskTranslation('fields.description') },
    { index: 'enabled', title: reportTaskTranslation('fields.enabled') },
    {
      index: 'actions',
      title: reportTaskTranslation('fields.actions'),
      maxWidth: 120,
      divider: 'left',
    },
  ];
  const tableData = reportTaskList?.live_report_tasks.map((reportTask) => ({
    ...reportTask,
    enabled: (
      <div className={`${reportTask.enabled ? 'text-basic_green' : 'text-basic_red'}`}>
        {reportTaskTranslation(String(reportTask.enabled))}
      </div>
    ),
    actions: (
      <div className="flex items-center gap-[5px] h-full">
        <CircleButton
          onClick={() => onSettingsClick?.(reportTask.report_task_id)}
          className="cursor-pointer hover:text-action"
          size={17}
          icon={'SettingIcon'}
          hintTitle={t('settings')}
        ></CircleButton>
        <CircleButton
          onClick={() => onRecipientsClick?.(reportTask.report_task_id)}
          className="cursor-pointer hover:text-action"
          icon={'UserIcon'}
          size={17}
          hintTitle={t('mailing_list_recipients')}
        ></CircleButton>
        <CircleButton
          icon={'TrashIcon'}
          onClick={() => onTrashIconClick?.(reportTask.report_task_id)}
          hintTitle={t('delete')}
          size={18}
          disable={report_cant_be_edited}
          className="cursor-pointer text-basic_red"
        ></CircleButton>
      </div>
    ),
  }));

  function onSettingsClick(report_task_id: string) {
    changeModalEditMode(true);
    viewReportTaskData({ report_task_id })
      .unwrap()
      .then((data) => {
        changeReportTaskObject(data);
        changeActiveReportTask(report_task_id);
        changeTaskModalState(true);
      });
  }
  function onRecipientsClick(report_task_id: string) {
    handleRecipients(report_task_id);
  }
  function onTrashIconClick(report_task_id: string) {
    changeTaskForDelete(report_task_id);
  }
  function onCloseModal() {
    changeTaskModalState(false);
    changeModalEditMode(false);
  }
  function handleDeleteReportTask() {
    if (!reportTaskIdForDelete) return;
    deleteReportTask({ report_task_id: reportTaskIdForDelete });
  }
  function onCreateNewRecipient(
    platform: 'telegram' | 'email',
    body: { email: string; description: string },
  ) {
    if (!addRecipientReportId) return;
    return new Promise<{ status: boolean; recipient_id?: string; hash?: string }>((resolve) => {
      if (platform === 'email') {
        createNewEmailRecipient({ ...body, report_task_id: addRecipientReportId })
          .unwrap()
          .then((data) => {
            handleGetSubscribedRecipients(addRecipientReportId);
            resolve({ status: true, recipient_id: data.recipient_id });
          })
          .catch(() => resolve({ status: false }))
          .finally(() => setTimeout(() => refetch(), 100));
      } else {
        createNewTelegramRecipient({
          report_task_id: addRecipientReportId,
          description: body.description,
        })
          .unwrap()
          .then((data) => {
            handleGetSubscribedRecipients(addRecipientReportId);
            resolve({ status: true, recipient_id: data.recipient_id, hash: data.hash });
          })
          .catch(() => resolve({ status: false }))
          .finally(() => setTimeout(() => refetch(), 100));
      }
    });
  }
  function handleChangeRecipientStatus(
    status: boolean,
    platform: TriggerPlatformType,
    recipient_id: string,
  ) {
    const report_task_id = addRecipientReportId;
    if (!report_task_id) return;
    switch (platform) {
      case 'telegram':
        status
          ? addTgRecipientToReport({ recipient_id, report_task_id })
              .unwrap()
              .then(() => toast.success(err('success')))
              .catch(() => toast.error(err('error')))
              .finally(() => handleGetSubscribedRecipients(report_task_id))
          : deleteTgRecipientFromReport({
              report_task_id,
              report_task_telegram_recipient_id: recipient_id,
            })
              .unwrap()
              .then(() => toast.success(err('success')))
              .catch(() => toast.error(err('error')))
              .finally(() => handleGetSubscribedRecipients(report_task_id));
        break;
      case 'email':
        status
          ? addEmailRecipientToReport({ recipient_id, report_task_id })
              .unwrap()
              .then(() => toast.success(err('success')))
              .catch(() => toast.error(err('error')))
              .finally(() => handleGetSubscribedRecipients(report_task_id))
          : deleteEmailRecipientFromReport({
              report_task_email_recipient_id: recipient_id,
              report_task_id,
            })
              .unwrap()
              .then(() => toast.success(err('success')))
              .catch(() => toast.error(err('error')))
              .finally(() => handleGetSubscribedRecipients(report_task_id));
        break;
      default:
        break;
    }
  }
  const telegramDefaultRecipients = useMemo(
    () =>
      tenantRecipients?.telegram.recipients.map(({ description, recipient_id, confirmed }) => ({
        description: description,
        recipient_id: recipient_id,
        confirmed,
      })),
    [tenantRecipients?.telegram.recipients],
  );

  const emailDefaultRecipients = useMemo(
    () =>
      tenantRecipients?.email.recipients.map(({ description, email, recipient_id, confirmed }) => ({
        description: description,
        email: email,
        confirmed,
        recipient_id: recipient_id,
      })),
    [tenantRecipients?.email.recipients],
  );

  const recipientsDefaultValue = {
    telegram: {
      recipients: telegramDefaultRecipients,
      total: tenantRecipients?.telegram.total,
    },
    email: { recipients: emailDefaultRecipients, total: tenantRecipients?.email.total },
  };

  return (
    <div>
      <div className="flex flex-col gap-[10px]">
        {/* <Box className="inline-flex w-full justify-between flex-col"> */}
        <div className="pb-[30px] pt-[10px]">
          <EasyFilterView
            dataIdStartDate="reports-settings-date-from"
            dataIdEndDate="reports-settings-date-to"
            data={newFilterValues}
            footerMarkup={footerMarkup}
            titleMarkup={titleMarkup}
            onChangeHandler={onChangeFilterHandler}
            keysForFiltering={filteringKeys || []}
            disabledChangeButton={
              !liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW
            }
          />
        </div>
        {/* </Box> */}
        {currentTemplate == 'chat' ? (
          <div></div>
        ) : (
          <PermissionAccessWrapper
            tag={userPermissions.display.displayLiveReportsTasks}
            permissions={'display'}
          >
            <div className="flex flex-col gap-[30px]">
              <div className="pt-[20px] pb-[20px] pr-[25px] pl-[22px] bg-white border-l-[2px] border-l-speech_analitics rounded-xl flex w-full justify-between items-center">
                <h2 className="text-1color font-bold">{reportTaskTranslation('block_title')}</h2>
                <Button
                  icon="CirclePlusIcon"
                  label={reportTaskTranslation('create_task')}
                  onClick={() => {
                    changeModalEditMode(false);
                    changeTaskModalState(true);
                  }}
                  disabled={report_cant_be_edited}
                />
              </div>

              <RubberTable dataSource={tableData || []} columns={tableColumns} />
            </div>
          </PermissionAccessWrapper>
        )}
      </div>
      {reportTaskModalState && (
        <ReportTaskModalForm
          report_cant_be_edited={report_cant_be_edited}
          activeReportTask={activeReportTask}
          modalEditMode={modalEditMode}
          show={reportTaskModalState}
          onClose={onCloseModal}
          live_report_id={id}
          reportTaskObject={reportTaskObject}
          changeTaskModalState={changeTaskModalState}
        />
      )}
      <EasyModal
        show={!!reportTaskIdForDelete}
        onRemove={handleDeleteReportTask}
        onClose={() => changeTaskForDelete(undefined)}
        variant="removal"
        label={reportTaskTranslation('delete_modal_label')}
      >
        <div className="flex w-full justify-center">
          {reportTaskTranslation('delete_modal_text')}
        </div>
      </EasyModal>
      <AddRecipientsModal
        hideAddRecipientsFeature
        tenantRecipients={recipientsDefaultValue}
        show={!!addRecipientReportId}
        subscribeDisable={report_cant_be_edited}
        filterParams={filterParams}
        changeFilterParams={changeFilterParams}
        onClose={() => changeAddRecipientReportId(undefined)}
        triggerSubscribedRecipients={reportSubscribedRecipients}
        onCreateNewRecipient={onCreateNewRecipient}
        handleChangeRecipientStatus={handleChangeRecipientStatus}
        changeTriggerSubscribedRecipients={changeReportSubscribedRecipients}
      />
    </div>
  );
};

export default ReportSettingsPage;
