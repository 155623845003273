import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { BreadcrumbsItem } from '@app/layouts/layout';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { analyticRoute } from '@app/routes/analytic.route';
import RequiredAuth from '@app/routes/required-auth.route';
import { recordsRoute } from '@app/routes/records.route';
import { reportsRoute } from '@app/routes/reports.route';
import { lastRecordRoute } from '@app/routes/last-record.route';
import { MetricTemplates } from '@app/pages/metric-templates/metric-templates.page';
import { dictionariesRoute } from '@app/routes/dictionaries.route';
import { settingsRoute } from '@app/routes/settings.route';
import { AuthorizationPage, EmailPage, ErrorBoundaryPage, SettingsOutlet } from '@app/pages';
import { RouterLoadingHook } from '@app/routes/router-loading-hook';
import { wordsNGramm } from '@app/routes/words-n-gramm.route';
import HelpPage from '@app/help-page/help.page';
import DashboardPageSkeleton from '@app/pages/dashboards/blocks/dashboard-page.skeleton';

import LayoutSwitcher from '@app/layouts/layout-switcher';

import { presetTemplateRoute } from './routes/preset-templates.route';
const DashboardPage = lazy(() => import('./pages/dashboards'));
import AuditLogs from './pages/audit-logs';

import UserPage from './pages/users/';

import { wordsCloudRoute } from './routes/words-cloud.route';
import { chatAnalyticRoute } from './routes/chat-template-routes/chat-analytic.route';
import { allChatsRoute } from './routes/chat-template-routes/all-chats.route';
import { chatMetricTemplateRoute } from './routes/chat-template-routes/chat-metric-template.route';
import { subscribeRoute } from './routes/subscribe.route';
import { subscribeErrorRoute } from './routes/subscribe_error.route';
import { unsubscribeFromAllRoute } from './routes/unsubscribe-from-all.route';
import { unsubscribeFromTriggerRoute } from './routes/unsubscribe-from-trigger.route';
import { unsubscribeFromReportRoute } from './routes/unsubscribe-from-report.route';
import { unsubscribeFromReportErrorRoute } from './routes/unsubscribe-from-report-error.route';
import { unsubscribeFromAllErrorRoute } from './routes/unsubscribe-from-all-error.route';
import { unsubscribeFromTriggerErrorRoute } from './routes/unsubscribe-from-trigger-error.route';
import { chatLastRecordRoute } from './routes/chat-template-routes/chat-last-record.route';
import { chatReportsRoute } from './routes/chat-template-routes/chat-reports.route';
import { chatWordsNGramm } from './routes/chat-template-routes/words-n-gramm-chats.route';
import { qmAppelRoute } from './routes/QM-module-routes/qm-appeal.route';
import { qmManageRoute } from './routes/QM-module-routes/qm-manage.route';
import { qmStatisticRoute } from './routes/QM-module-routes/qm-statistic.route';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <LayoutSwitcher
          switchOffLayout={{
            [navigationRoutes.staticReport]: 'EmailLayout',
            support: 'HelpLayout',
          }}
        />
      }
      errorElement={<ErrorBoundaryPage />}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <Suspense fallback={<DashboardPageSkeleton />}>
              <DashboardPage />
            </Suspense>
          </RequiredAuth>
        }
      />
      {/* RECORDS ROUTES */}
      {recordsRoute}
      {/* ANALYTICS */}
      {analyticRoute}
      {/* ANALYTICS chat */}
      {chatAnalyticRoute}
      {/*REPORTS*/}
      {reportsRoute}
      {/*chat - REPORTS*/}
      {chatReportsRoute}
      {/* latest project are looked */}
      {lastRecordRoute}
      {/* latest project are looked from chats */}
      {chatLastRecordRoute}
      {/* DictionariesPage */}
      {dictionariesRoute}
      {/* Preset Templates Page */}
      {presetTemplateRoute}
      {/* subscribe */}
      {subscribeRoute}
      {/* subscribeError */}
      {subscribeErrorRoute}
      {/*  */}
      {unsubscribeFromAllRoute}
      {/*  */}
      {unsubscribeFromTriggerRoute}
      {/*  */}
      {unsubscribeFromReportRoute}
      {/*  */}
      {unsubscribeFromAllErrorRoute}
      {/*  */}
      {unsubscribeFromTriggerErrorRoute}
      {/*  */}
      {unsubscribeFromReportErrorRoute}
      {/* QM module 2.5 */}
      {qmAppelRoute}
      {qmManageRoute}
      {qmStatisticRoute}
      {/* QM module 2.5 */}
      <Route
        path={navigationRoutes.metric}
        handle={{
          crumb: () => <BreadcrumbsItem path={navigationRoutes.metric} crumb="metric_templates" />,
        }}
      >
        {/* METRIC TEMPLATES */}
        <Route
          index
          element={
            <RequiredAuth>
              <MetricTemplates currentTemplate="voice" />
            </RequiredAuth>
          }
        />
        {/* METRIC TEMPLATES */}
      </Route>
      {/* SETTINGS */}
      {settingsRoute}
      {wordsCloudRoute}
      {wordsNGramm}
      {chatWordsNGramm}
      <Route
        path={navigationRoutes.settings}
        element={
          <RequiredAuth>
            <SettingsOutlet />
          </RequiredAuth>
        }
        handle={{
          crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
        }}
      ></Route>
      <Route
        path={navigationRoutes.authorization + '/*'}
        element={
          <RouterLoadingHook>
            <AuthorizationPage />
          </RouterLoadingHook>
        }
      />

      <Route
        path={navigationRoutes.auditLogs}
        element={
          <RequiredAuth>
            <AuditLogs />
          </RequiredAuth>
        }
      />
      <Route
        path={navigationRoutes.users}
        element={
          <RequiredAuth>
            <UserPage />
          </RequiredAuth>
        }
      />
      <Route path="support">
        <Route index element={<HelpPage />} />
        <Route path=":content_id" element={<HelpPage />} />
      </Route>
      <Route path={navigationRoutes.staticReport} index element={<EmailPage />} />
      <Route path={navigationRoutes.error} element={<ErrorBoundaryPage />} />
      {/* chat template routes */}

      {allChatsRoute}
      {chatMetricTemplateRoute}
      {/* chat template routes */}
    </Route>,
  ),
);
