import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, Select } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { IntegrationAccessKeyObject } from '@app/interfaces/settings.type';
type IntegrationFormPropsType = {
  onFormSubmitHandler(
    data: { name: string; api: boolean; upload_records: boolean },
    newKeys?: boolean,
  ): void;
  integrationData: IntegrationAccessKeyObject | undefined;
  editMode: boolean;
  onFormDeleteHandler(): void;
};
export const IntegrationForm: FC<IntegrationFormPropsType> = (props) => {
  const { onFormSubmitHandler, integrationData, editMode, onFormDeleteHandler } = props;
  const [selectedIntegrationType, setSelectedIntegrationType] = useState<string>(
    integrationData?.api ? 'integrationapi' : integrationData?.upload_records ? 'rest' : 'chatapi',
  );
  const { t } = useTranslation('pages.settings.integrations.form');
  const { register, handleSubmit, getValues } = useForm<{
    name: string;
    integrationType: string;
  }>({
    defaultValues: {
      name: editMode ? integrationData?.name : undefined,
      integrationType: editMode && integrationData?.api ? 'integrationapi' : 'rest',
    },
  });
  function handleSubmitForm(data: { name: string; integrationType: string }) {
    onFormSubmitHandler(createIntegrationData(data.name, selectedIntegrationType));
  }

  const createIntegrationData = (name, integrationType) => ({
    name,
    api: integrationType === 'integrationapi',
    upload_records: integrationType === 'rest',
    upload_chats: integrationType === 'chatapi',
  });

  const enabledOptions = [
    { title: t('types.integrationapi'), value: 'integrationapi' },
    { title: t('types.rest'), value: 'rest' },
    { title: t('types.chatsApi'), value: 'chatapi' },
  ];

  function onCreateNewKeys() {
    const formValues = getValues();
    onFormSubmitHandler(createIntegrationData(formValues.name, selectedIntegrationType), true);
  }
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={'px-[40px]'}>
        <div className={'flex items-center gap-[108px]'}>
          <div className={'text-1color text-[15px] font-[500] leading-[16.4px]'}>{t('name')}</div>
          <Input {...register('name')} />
        </div>
        {!editMode && (
          <div className={'flex items-center mt-[20px] gap-[60px]'}>
            <div className={'text-1color text-[15px] font-[500] leading-[16.4px] w-[190px]'}>
              {t('type')}
            </div>
            <Select
              defaultValue={selectedIntegrationType}
              options={enabledOptions}
              onChange={(value) => setSelectedIntegrationType(value as string)}
            />
          </div>
        )}
        {editMode && (
          <div
            className={
              'text-1color text-[15px] mt-[20px] flex w-full gap-[60px] font-[500] leading-[16.4px]'
            }
          >
            <h1>{t('type2')}</h1>
            {integrationData?.api ? t('types.integrationapi') : t('types.rest')}
          </div>
        )}
        <div className={'w-full flex flex-col gap-[10px] items-center mt-[50px]'}>
          {editMode && (
            <Button
              onClick={onCreateNewKeys}
              type={'button'}
              label={t('newKeysButton')}
              fill={'linked'}
            />
          )}
          <Button
            type={'submit'}
            className={'!px-[80px] !py-[20px]'}
            isFull
            label={t('saveButton')}
          />
          {editMode && (
            <Button
              onClick={onFormDeleteHandler}
              label={t('deleteButton')}
              fill={'linked'}
              variant={'danger'}
            />
          )}
        </div>
      </div>
    </form>
  );
};
