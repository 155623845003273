import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import { Route } from 'react-router-dom';
import {
  MetricPage,
  PresetPage,
  ProjectRecordsPage,
  ProjectSettingsPage,
  ProjectReportsPage,
  SingleAnalyticPage,
  NewProjectPage,
} from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';
import ProjectReportsOutlet from '@app/pages/analytics/project-reports/project-reports.outlet';
import ProjectChartsPage from '@app/pages/analytics/project-reports/project-charts.page';
import { AnalyticsProjects } from '@app/pages/analytics-projects';
import { MetricTriggerPage } from '@app/pages/analytics/metric-trigger';

export const analyticRoute = (
  <Route
    path={navigationRoutes.analytics}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.analytics} crumb="analytics" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <AnalyticsProjects />
        </RequiredAuth>
      }
    />
    <Route
      path={navigationRoutes.newProject}
      element={
        <RequiredAuth>
          <NewProjectPage />
        </RequiredAuth>
      }
    />

    <Route
      path=":id"
      loader={async ({ params }) => {
        return { projectId: params.id };
      }}
      element={
        <RequiredAuth>
          <SingleAnalyticPage />
        </RequiredAuth>
      }
      handle={{
        crumb: (data) => {
          const { projectId } = data as { projectId: string };
          return (
            <BreadcrumbsItem
              path={`${navigationRoutes.analytics}/${projectId}`}
              crumb="analytic_project"
            />
          );
        },
      }}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <ProjectSettingsPage />
          </RequiredAuth>
        }
      />
      <Route path={navigationRoutes.metric} element={<MetricPage />} />
      <Route path={navigationRoutes.presets} element={<PresetPage />} />
      <Route path={navigationRoutes.triggers} element={<MetricTriggerPage />} />
      <Route path={navigationRoutes.reports}>
        <Route
          index
          element={
            <RequiredAuth>
              <ProjectReportsPage currentTemplate="voice" />
            </RequiredAuth>
          }
        />
        <Route path=":report_id" element={<ProjectReportsOutlet />}>
          <Route
            index
            element={
              <RequiredAuth>
                <ProjectChartsPage />
              </RequiredAuth>
            }
          />
        </Route>
      </Route>
      <Route
        path={navigationRoutes.projectRecords}
        element={
          <RequiredAuth>
            <ProjectRecordsPage />
          </RequiredAuth>
        }
      />
    </Route>
  </Route>
);
