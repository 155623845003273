import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { Route } from 'react-router-dom';
import { BreadcrumbsItem } from '@app/layouts/layout';
import { QmManagePage } from '@app/pages/qm-module';

export const qmManageRoute = (
  <Route
    path={navigationRoutes.qm_module_manage}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.qm_module_manage} crumb="qm_manage" />,
    }}
    element={<QmManagePage />}
  />
);
