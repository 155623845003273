import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  AllStatusProject,
  CreateProjectAnalyticsParams,
  GroupsListObjectItem,
  ProjectAnalyticsParams,
  ProjectItem,
} from '@app/interfaces/analytics';

import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-chat-page-props.type';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import { baseQuery } from '../../baseQuery';

export const chatAnalyticProjectApi = createApi({
  reducerPath: 'chatApi',
  tagTypes: ['analyticsProject', 'singleProject', 'status'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getChatGroup: build.query<GroupsListObjectItem[], void | null>({
      query: () => ({
        url: serverRoutes.chatGroup,
      }),
      transformResponse(response: { projects_groups: GroupsListObjectItem[] }) {
        return response.projects_groups;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ project_group_id }) => ({
                type: 'analyticsProject' as const,
                project_group_id,
              })),
              'analyticsProject',
            ]
          : ['analyticsProject'],
    }),
    createChatGroup: build.mutation({
      query: (body) => ({
        url: serverRoutes.newChatGroup,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    deleteChatProjectsGroup: build.mutation<GroupsListObjectItem, { id: string }>({
      query: (params) => ({
        url: serverRoutes.newChatGroup,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    // single chat project
    deleteChatProject: build.mutation<ProjectAnalyticsParams, { id: string }>({
      query: (params) => ({
        url: serverRoutes.chatProject,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    createSingleChatProject: build.mutation<ProjectItem, CreateProjectAnalyticsParams>({
      query: (body) => ({
        url: serverRoutes.chatProject,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    getSingleChatProject: build.query<ProjectItem, { id: string }>({
      query: (params) => ({
        url: serverRoutes.chatProject,
        params,
      }),
      providesTags: ['singleProject'],
    }),

    editSingleChatAnalyticProject: build.mutation<
      ProjectItem,
      { body: CreateProjectAnalyticsParams; params: { id: string } }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.chatProject,
        method: 'PUT',
        body,
        params,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    editSingleChatAnalyticProjectOwner: build.mutation<ProjectItem, { params: { id: string } }>({
      query: ({ params }) => ({
        url: serverRoutes.analyticsChatProjectTake,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['analyticsProject', 'singleProject'],
    }),
    // single chat project

    //status
    changeStatusOfChatAnalyticProject: build.mutation<
      { project_status: AllStatusProject },
      { params: { id: string }; body: { status: AllStatusProject } }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.changeAnalyticChatStatus,
        method: 'PUT',
        body,
        params,
      }),
      invalidatesTags: ['analyticsProject', 'singleProject', 'status'],
    }),
    getStatusOfChatAnalyticProject: build.query<
      { project_status: AllStatusProject },
      { id: string | undefined }
    >({
      query: (params) => ({
        url: serverRoutes.getAnalyticChatStatus,
        params,
      }),
      providesTags: ['status'],
      async onQueryStarted(_, { dispatch }) {
        dispatch(chatAnalyticProjectApi.util.invalidateTags(['singleProject']));
      },
    }),
    getChatAnalyticProjectsForLastProjects: build.query<ProjectItem[], void>({
      query: () => ({
        url: serverRoutes.projectsChat,
      }),
      transformResponse(baseQueryReturnValue: { projects: ProjectItem[] }) {
        return baseQueryReturnValue.projects;
      },
      // providesTags: ['singleProject'],
    }),
    //status

    createSingleChatPreset: build.mutation<PresetsDataType, PresetsCreateDataType>({
      query: ({ project_id, body }) => ({
        url: serverRoutes.chatPreset,
        body,
        params: { project_id },
        method: 'PUT',
      }),
    }),

    getAnalyticsChatProjectPreset: build.query<
      { preset: (Filter & Range) | null },
      { project_id: string }
    >({
      query: (params) => ({
        url: serverRoutes.chatPreset,
        params,
      }),
    }),
    // mass actions
    deleteManyChatProjects: build.mutation<string, ProjectAnalyticsParams[]>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const deleteProject = async (id: string) =>
          await fetchWithBQ({
            url: serverRoutes.chatProject,
            params: { id },
            method: 'DELETE',
          });
        for (const project of arg) {
          project.project_id && (await deleteProject(project.project_id));
        }
        return { data: 'all good' };
      },
      invalidatesTags: ['analyticsProject'],
    }),
    editManyChatProjects: build.mutation<
      string,
      { projects: ProjectAnalyticsParams[]; groupId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const editProject = async (project: ProjectAnalyticsParams) =>
          await fetchWithBQ({
            url: serverRoutes.chatProject,
            params: { id: project.project_id },
            method: 'PUT',
            body: project,
          });
        for (const project of arg.projects) {
          await editProject({ ...project, project_group_id: arg.groupId });
        }
        return { data: 'all good' };
      },
      invalidatesTags: ['analyticsProject'],
    }),
  }),
});

export const {
  useGetAnalyticsChatProjectPresetQuery,
  useCreateSingleChatPresetMutation,
  useGetChatGroupQuery,
  useCreateChatGroupMutation,
  useDeleteChatProjectsGroupMutation,
  useDeleteChatProjectMutation,
  useCreateSingleChatProjectMutation,
  useGetSingleChatProjectQuery,
  useChangeStatusOfChatAnalyticProjectMutation,
  useGetStatusOfChatAnalyticProjectQuery,
  useEditSingleChatAnalyticProjectMutation,
  useEditSingleChatAnalyticProjectOwnerMutation,
  useGetChatAnalyticProjectsForLastProjectsQuery,
  useDeleteManyChatProjectsMutation,
  useEditManyChatProjectsMutation,
} = chatAnalyticProjectApi;
