import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import {
  ReportChartPage,
  ReportsPage,
  ReportOutlet,
  ReportRecordsPage,
  ReportSettingsPage,
  ReportRecordPage,
} from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';

export const reportsRoute = (
  <Route
    path={navigationRoutes.reports}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.reports} crumb="reports" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <ReportsPage currentTemplate="voice" />
        </RequiredAuth>
      }
    />
    <Route
      path=":id"
      loader={async ({ params }) => {
        return { live_report_id: params.id };
      }}
      element={<ReportOutlet currentTemplate={'voice'} />}
      handle={{
        crumb: (data) => {
          const { live_report_id } = data as { live_report_id: string };
          return (
            <BreadcrumbsItem
              path={`${navigationRoutes.reports}/${live_report_id}`}
              crumb="report"
            />
          );
        },
      }}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <ReportChartPage />
          </RequiredAuth>
        }
      />
      <Route
        path={navigationRoutes.settings}
        loader={async ({ params }) => {
          return { live_report_id: params.id };
        }}
        element={<ReportSettingsPage />}
        handle={{
          crumb: (data) => {
            const { live_report_id } = data as { live_report_id: string };
            return (
              <BreadcrumbsItem
                path={`${navigationRoutes.reports}/${live_report_id}/${navigationRoutes.settings}`}
                crumb="report_settings"
              />
            );
          },
        }}
      />
      <Route
        path={navigationRoutes.reportRecords}
        loader={async ({ params }) => {
          return { live_report_id: params.id };
        }}
        handle={{
          crumb: (data) => {
            const { live_report_id } = data as { live_report_id: string };
            return (
              <BreadcrumbsItem
                path={`${navigationRoutes.reports}/${live_report_id}/${navigationRoutes.reportRecords}`}
                crumb="report_list"
              />
            );
          },
        }}
      >
        <Route
          index
          element={
            <RequiredAuth>
              <ReportRecordsPage />
            </RequiredAuth>
          }
        />
        <Route
          path=":analytics_id/:record_id"
          handle={{
            crumb: () => <BreadcrumbsItem path={navigationRoutes.reports} crumb="recordsId" />,
          }}
          element={
            <RequiredAuth>
              <ReportRecordPage />
            </RequiredAuth>
          }
        />
      </Route>
    </Route>
  </Route>
);
