module.exports = {
  error_page_label: 'Проекты не найдены',
  popup_hints: {
    to_record_title: 'Перейти к просмотру записи',
    download_record: 'Скачать запись',
  },
  filter: {
    title: 'Фильтровать записи проекта',
    buttons: {
      create_report: 'Создать LIVE отчет на основе фильтра',
      create_report_rtk: 'Создать отчет на основе фильтра',
      reset_filter: 'Очистить',
    }
  },
  select_analytic_project: {
    label: 'Аналитический проект',
    select_placeholder: 'Укажите аналитический проект'
  },
  table: {
    first_column_title: 'Длительность',
    last_column_title: 'Действия',
  },
  metric_records: 'Записей в очереди на расчет: '
}
