import React from 'react';
import { useNavigate } from 'react-router';
import { v4 } from 'uuid';

import { CircleButton, TooltipWrapper } from '@app/components/ui';
import { TableItem } from '@app/components/table/table.type';
import { UniversalFilterConstants } from '@app/hooks/use-filter-data';
import { getFrontendDate } from '@app/utils/helpers';
import Icon from '@app/components/ui/icons';
import {
  FilterItemNotOnlyArray,
  FilterRangeParametersItemForLType,
  FilterRangeParametersItemForRType,
} from '@app/interfaces/filter';
import { navigationRoutes } from '@app/utils/navigation-routes';
import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import RubberTable from 'src/components/rubber-table';
import useTranslation from '@app/hooks/use-translation';
import { MenuSmallIcon } from '@app/components/ui/icons/icons-list';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import {
  useCreateSinglePresetMutation,
  useDeletePresetTemplateMutation,
} from '@app/store/api/preset-template.api';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { EasyModal } from '@ui';

type PropsType = {
  presetGroup: PresetsDataType[];
  groupName: string;
};

export const TableChildren: React.FC<PropsType> = ({ presetGroup, groupName }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages.presets');
  const { metaDateTime, metaDateTimeCalendarForRangeShow } = UniversalFilterConstants();

  const [deletePresetModal, setDeletePresetModal] = React.useState<PresetsDataType | null>(null);
  //api
  const { data: aliases } = useGetAliasesListQuery();
  const [deletePreset] = useDeletePresetTemplateMutation();
  const [createPreset] = useCreateSinglePresetMutation();

  const columns = [
    {
      title: t('all_preset_templates_table.name'),
      index: 'name',
    },
    {
      title: t('all_preset_templates_table.time'),
      index: 'time',
    },
    {
      title: t('all_preset_templates_table.desc'),
      index: 'filter',
    },
    {
      title: t('all_preset_templates_table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];

  function copyPreset(preset: PresetsDataType) {
    const newPreset: PresetsCreateDataType = {
      name: preset?.name + ` ${t('copy')}`,
      description: '',
      preset_group_id: preset.preset_group_id,
      shared: false,
      rules: preset.rules as Filter & Range,
    };
    createPreset(newPreset).then((data) => {
      const { data: presetData } = data as unknown as { data: PresetsDataType };
      navigate(`/${navigationRoutes.presets}/${presetData.preset_id}`);
    });
  }

  const deleteSinglePreset = () => {
    if (deletePresetModal?.preset_id) {
      deletePreset({ id: deletePresetModal?.preset_id }).then(() => {
        setDeletePresetModal(null);
      });
    }
  };
  const clickCircleDelete = (preset: PresetsDataType) => {
    setDeletePresetModal(preset);
  };
  const getAliasesNameById = (id: string) => {
    return aliases?.aliases.find((alias) => alias.alias_id === id)?.name || 'N/A';
  };
  const getAliasesTypeById = (id: string) => {
    return aliases?.aliases.find((alias) => alias.alias_id === id)?.type_value || id;
  };
  const getRangeNameTypeId = (type: string) => {
    return (
      metaDateTime.find((item) => item.id === type)?.name ||
      metaDateTimeCalendarForRangeShow?.[type]
    );
  };
  const getFilterValues = (filter: FilterItemNotOnlyArray) => {
    if (Array.isArray(filter.value)) {
      return filter?.value.map((val, index) => {
        return (
          <span className="mr-[6px] last:mr-[0px]" key={index}>
            {val}
          </span>
        );
      });
    } else if (getAliasesTypeById(filter.id) === 'datetime') {
      return getFrontendDate(filter.value as string, 'dd.MM.yyyy HH:mm');
    } else {
      return filter.value;
    }
  };

  const filteredArr = () => {
    return presetGroup?.map((dataItem) => ({
      ...dataItem,
      utils: (
        <div className="flex pl-[10px] w-[140px] gap-[10px] items-center border-l-[1px] border-[#F1F2F3]">
          <CircleButton
            hintTitle={t('popup_hints.settings_template_icon')}
            dataId={`preset-template-${groupName}-${dataItem.name}-configure`}
            icon="SettingIcon"
            size={18}
            className={`text-3color hover:text-action`}
            onClick={() => navigate(`/${navigationRoutes.presets}/${dataItem.preset_id}`)}
          />
          <CircleButton
            hintTitle={t('popup_hints.copy_template_icon')}
            dataId={`preset-template-${groupName}-${dataItem.name}-copy`}
            icon="CopyIcon"
            size={18}
            className={`text-3color hover:text-action`}
            onClick={() => copyPreset(dataItem)}
          />
          <CircleButton
            dataId={`preset-template-${groupName}-${dataItem.name}-delete`}
            hintTitle={t('popup_hints.delete_template_icon')}
            icon="TrashIcon"
            size={18}
            onClick={() => clickCircleDelete(dataItem)}
            className={`text-basic_red`}
          />
        </div>
      ),
      name: (
        <div className="text-0color flex items-center font-[600] tracking-tight text-[14px] leading-[110%]">
          <div className="min-h-[28px] min-w-[28px] max-h-[28px] max-w-[28px] flex items-center justify-center ml-[16px] mr-[12px] bg-blue_pal rounded-[6px]">
            <MenuSmallIcon size={13} className="text-action" />
          </div>
          <TooltipWrapper content={dataItem.name} id={dataItem.name + v4()}>
            <div className="truncate" data-id={`preset-template-${groupName}-${dataItem.name}`}>
              {dataItem.name}
            </div>
          </TooltipWrapper>
        </div>
      ),
      time: (
        <div className="flex items-center w-full text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
          <Icon name={'CalendarIcon'} className="mr-[10px]" />
          {dataItem.rules.range.parameters.map((param, index) => {
            if (dataItem.rules.range.type === 'r') {
              const dateAndTime = (param as FilterRangeParametersItemForRType).value.split(' ');
              return (
                <span key={`date-${index}`}>
                  {index === 1 && <span className="inline-block mr-[5px] ml-[5px]">-</span>}
                  <span className="inline-block">
                    {index === 0 ? `${t('from')} ` : `${t('to')} `}
                    {getFrontendDate(dateAndTime[0], 'dd.MM.yyyy')}
                  </span>
                  <span className="inline-block">{dateAndTime[1]}</span>
                </span>
              );
            }
            if (dataItem.rules.range.type === 'l') {
              return (
                <span key={`date-${index}`} className="inline-block">
                  {(param as FilterRangeParametersItemForLType).num + ` ${t('last_days')}`}
                </span>
              );
            }
            return <></>;
          })}
          {dataItem.rules.range.type !== 'r' ? (
            dataItem.rules.range.type !== 'l' ? (
              <span>
                {getRangeNameTypeId(dataItem.rules.range.type) || `${dataItem.rules.range.type}`}
              </span>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      ),
      filter: (
        <div className="max-h-[31px]">
          <div className="text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
            {dataItem.rules.filter.map((filter, index) => {
              return (
                <span
                  key={filter.id + index}
                  className="mr-[16px] py-2 last:mr-[0px] block w-full truncate"
                >
                  <span>{getAliasesNameById(filter.id)}</span>
                  <span className="mr-[6px] ml-[6px] w-[19px] h-full border-[1px] border-solid border-[#A6ABBA] rounded-[50%] p-[0px_4px_1px_5px]">
                    {filter.condition}
                  </span>

                  {filter.value[0] !== '' && (
                    <span className="bg-basic_app_bg p-[2px_8px_3px] rounded-[4px] text-2color w-full">
                      {getFilterValues(filter)}
                    </span>
                  )}
                </span>
              );
            })}
          </div>
        </div>
      ),
    })) as unknown as Array<TableItem>;
  };
  return (
    <>
      <EasyModal
        dataId={`${deletePresetModal?.name}-delete-confirm`}
        show={!!deletePresetModal}
        onClose={setDeletePresetModal.bind(null, null)}
        label={
          <div className="px-[5px] w-full break-words" title={deletePresetModal?.name}>
            {t('warning')} <br /> {deletePresetModal?.name}
          </div>
        }
        variant="removal"
        onRemove={() => deleteSinglePreset()}
        withoutFooter
      >
        <div className="px-[50px] text-center">{t('confirm_group_delete')}</div>
      </EasyModal>
      <div className="h-full">
        <RubberTable
          columns={columns}
          withoutCols
          dataSource={presetGroup !== null ? filteredArr() : []}
        />
      </div>
    </>
  );
};
