import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Button, CircleButton, Input, Select, TooltipWrapper } from '@ui';
import {
  ChatMetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import { AutoFocusHook } from '@app/utils/helpers';

import { AllStatusProject } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { Controller, useForm } from 'react-hook-form';

import { ChatMetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';

import { ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';

import {
  useCreateProjectChatMetricGroupMutation,
  useEditProjectChatMetricGroupMutation,
} from '@app/store/api/chat-api/chat-metric.api';

import {
  useCreateMetricTemplateMutation,
  useEditMetricTemplateMutation,
  useGetMetricTemplateGroupsListQuery,
} from '@app/store/api/metric-template.api';

import { VadModalsChildrenType } from '../../constantsForModals';
import { AddToTemplateModal } from '../../add-to-template-modal/add-to-template-modal';

type FormType = {
  name: string;
  c_role: string;
  exclude_begin_messages: number;
  exclude_end_messages: number;
  search_begin_messages: number;
  search_end_messages: number;
  select: string;
  threshold?: number;
};
const exceptionsMetricType = [
  'c_first-answer-duration',
  'c_pause-exceed',
  'c_longest-pause-duration',
  'c_avg-pause-duration',
];
export const ChatModals: React.FC<{
  unitVal: string;
  copyModal?: boolean;
  modalsSetState: (type: string) => void;
  dataFields: VadModalsChildrenType[];
  metric: MetricTypesArrType;
  chatRoles?: ChatRoleSettingsType[];
  editModal?: boolean;
  metricTemplate?: boolean;
  createFromTemplate?: boolean;
  status?: AllStatusProject | undefined;
  page?: string;
  viewOnly?: boolean;
  metricSettings?: ChatMetricObjType | undefined;
  metricTemplateSettings?: ChatMetricTemplatesType | undefined;
  currentTemplate: 'chat' | 'voice';
}> = ({
  dataFields,
  page,
  metric,
  editModal,
  modalsSetState,
  metricTemplate = false,
  createFromTemplate,
  status,
  chatRoles,
  copyModal,
  viewOnly,
  metricSettings,
  metricTemplateSettings,
  currentTemplate,
}) => {
  const { id } = useParams();
  // const { metricTemplates, metricTemplateSettings } = useAppSelector(
  //   (state) => state.metricTemplates,
  // );
  const { t: tf } = useTranslation('common.forms');
  const [createTemplateModal, setCreateTemplateModal] = React.useState(false);
  const { data: metricTemplates } = useGetMetricTemplateGroupsListQuery(currentTemplate);
  const [createMetric] = useCreateProjectChatMetricGroupMutation();
  const [editMetric] = useEditProjectChatMetricGroupMutation();
  const [editMetricTemplate] = useEditMetricTemplateMutation();
  const [createMetricTemplate] = useCreateMetricTemplateMutation();

  const defaultValue = (): FormType => {
    if (editModal) {
      if (metricTemplate) {
        return {
          name: `${metricTemplateSettings?.name || ''} ${copyModal ? t('copy') : ''}`.trim(),
          c_role: metricTemplateSettings?.settings.c_role || '',
          exclude_begin_messages: metricTemplateSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricTemplateSettings?.settings.exclude_end_messages || 0,
          search_begin_messages: metricTemplateSettings?.settings.search_begin_messages || 0,
          search_end_messages: metricTemplateSettings?.settings.search_end_messages || 0,
          select: metricTemplateSettings?.metric_templates_group_id || '',
          threshold: metricTemplateSettings?.settings?.threshold,
        };
      } else {
        return {
          name: metricSettings?.name || '',
          c_role: metricSettings?.settings.c_role || '',
          exclude_begin_messages: metricSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricSettings?.settings.exclude_end_messages || 0,
          search_begin_messages: metricSettings?.settings.search_begin_messages || 0,
          search_end_messages: metricSettings?.settings.search_end_messages || 0,
          select: '',
          threshold: metricSettings?.settings?.threshold,
        };
      }
    } else {
      if (createFromTemplate) {
        return {
          name: metricTemplateSettings?.name || '',
          c_role: metricTemplateSettings?.settings.c_role || '',
          exclude_begin_messages: metricTemplateSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricTemplateSettings?.settings.exclude_end_messages || 0,
          search_begin_messages: metricTemplateSettings?.settings.search_begin_messages || 0,
          search_end_messages: metricTemplateSettings?.settings.search_end_messages || 0,
          select: metricTemplateSettings?.metric_templates_group_id || '',
          threshold: metricTemplateSettings?.settings?.threshold,
        };
      } else
        return {
          name: '',
          c_role: '',
          exclude_begin_messages: 0,
          exclude_end_messages: 0,
          search_begin_messages: 0,
          search_end_messages: 0,
          select: 'default',
        };
    }
  };
  const { t } = useTranslation('pages.metricTemplates');
  const copyToClipboard = useCallback(() => {
    event?.preventDefault();
    let textToCopy: string | undefined = '';
    if (metricSettings && !metricTemplate) {
      textToCopy = metricSettings.metric_id;
    } else if (metricTemplateSettings && metricTemplate) {
      textToCopy = metricTemplateSettings.metric_template_id;
    }
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  }, [metricSettings, metricTemplateSettings, metricTemplate]);

  const {
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    control,
    clearErrors,
  } = useForm<FormType>({
    defaultValues: defaultValue(),
  });
  const disableFunc = () => {
    if (status === AllStatusProject.PAUSE) {
      return false;
    }
    return status !== AllStatusProject.CREATED;
  };

  const resetAsyncForm = () => {
    modalsSetState(metric.type);
  };
  const onSubmit = (data) => {
    const obj: ChatMetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        c_role: data.c_role,
        exclude_begin_messages: data.exclude_begin_messages,
        exclude_end_messages: data.exclude_end_messages,
        search_begin_messages: data.search_begin_messages,
        search_end_messages: data.search_end_messages,
        threshold: data.threshold,
      },
    };
    if (!editModal) {
      if (metricTemplate) {
        createMetricTemplate({ params: { metric_templates_group_id: watch('select') }, body: obj });
      } else {
        id && createMetric({ params: { project_id: id }, body: obj });
      }
    } else {
      if (metricSettings?.metric_id) {
        id &&
          editMetric({
            params: { project_id: id, metric_id: metricSettings.metric_id },
            body: obj,
          });
      } else if (metricTemplate) {
        if (copyModal) {
          createMetricTemplate({
            params: { metric_templates_group_id: watch('select') },
            body: obj,
          });
        } else {
          metricTemplateSettings?.metric_template_id &&
            editMetricTemplate({
              params: {
                id: metricTemplateSettings?.metric_template_id,
                metric_templates_group_id: watch('select'),
              },
              body: obj,
            });
        }
      }
    }
    resetAsyncForm();
  };
  const getDefaultTemplate = () => {
    return metricTemplates?.find((e) => e.is_default)?.metric_templates_group_id;
  };
  const createMetricTemplateApplyBtn = () => {
    const obj: ChatMetricObjType = {
      name: watch('name'),
      description: '',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        c_role: watch('c_role'),
        exclude_begin_messages: watch('exclude_begin_messages'),
        exclude_end_messages: watch('exclude_end_messages'),
        search_begin_messages: watch('search_begin_messages'),
        search_end_messages: watch('search_end_messages'),
      },
    };
    createMetricTemplate({
      params: { metric_templates_group_id: getDefaultTemplate() as string },
      body: obj,
    }).then(() => {
      resetAsyncForm();
      setCreateTemplateModal(false);
    });
    // .catch(() => {
    //   toast.error(t('fill_all'));
    // });
  };

  // eslint-disable-next-line
  const inputFocusRef = AutoFocusHook();

  const getSelectItems = () => {
    return (
      metricTemplates?.map((e) => {
        return { title: e.name, value: e.metric_templates_group_id };
      }) || []
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-[550px]">
        <div className="flex flex-col gap-[20px]">
          <div className="flex items-center justify-between">
            <span className="max-w-[218px] text-0color text-[15px]">{t('metric_group')}</span>
            <span className="mr-[165px]">
              <b>
                {metric.group.toUpperCase()} {t('one_metric')}
              </b>
            </span>
          </div>
          <div>
            {dataFields.map((e) => {
              return e.inputText.map((e) => {
                return (
                  <div key={e.id} className="flex items-center justify-between">
                    <span className="text-0color text-[15px]">{e.labelTitle}</span>
                    <div className="w-[280px]">
                      <Input
                        {...inputFocusRef}
                        {...register('name', {
                          required: { value: true, message: tf('required') },
                        })}
                        name="name"
                        placeholder={e.placeholder}
                        error={errors.name?.message}
                      />
                    </div>
                  </div>
                );
              });
            })}
          </div>
          {metricTemplate && (
            <div className="flex items-center justify-between">
              <span className="text-0color text-[15px]">{t('metric_templates_group')}</span>
              <div className="w-[280px]">
                <Controller
                  render={() => (
                    <Select
                      error={errors.select?.message}
                      defaultValue={watch('select')}
                      options={[
                        ...getSelectItems(),
                        {
                          title: t('choose') + '...',
                          value: 'default',
                          disabled: true,
                          nullable: true,
                        },
                      ]}
                      onChange={(value) => {
                        setValue('select', value as string);
                        clearErrors('select');
                      }}
                    />
                  )}
                  name={'select'}
                  rules={{ validate: (value) => value !== 'default' || tf('required') }}
                  control={control}
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-between mr-[140px] relative">
            <span className="max-w-[218px] text-0color text-[15px]">{t('role')}</span>
            <Controller
              render={() => (
                <div className="flex flex-col min-w-[130px] max-w-[130px]">
                  {!exceptionsMetricType.includes(metric.type) && (
                    <div className="flex items-center gap-[10px]">
                      <input
                        name="c_role"
                        onChange={(e) => {
                          setValue('c_role', e.target.value);
                          clearErrors('c_role');
                        }}
                        type="checkbox"
                        value={'all'}
                        checked={watch('c_role') === 'all'}
                        className="focus:ring-offset-0 focus:ring-0 rounded-full"
                      />
                      <label className="text-[14px] text-0color">{t('all')}</label>
                    </div>
                  )}
                  {chatRoles?.map((roleSettings) => {
                    return (
                      <div className="flex items-center gap-[10px]" key={roleSettings.crole_id}>
                        <input
                          name="c_role"
                          onChange={(e) => {
                            setValue('c_role', e.target.value);
                            clearErrors('c_role');
                          }}
                          type="checkbox"
                          checked={watch('c_role') === roleSettings.crole_id}
                          value={roleSettings.crole_id}
                          className="focus:ring-offset-0 focus:ring-0 rounded-full"
                        />
                        <label className="text-[14px] text-0color">{roleSettings.name}</label>
                      </div>
                    );
                  })}
                </div>
              )}
              name={'c_role'}
              control={control}
              rules={{ validate: (value) => !!value || tf('required') }}
            />
            {errors.c_role?.message && (
              <div
                className={
                  'absolute bottom-[-17px] right-[20px] text-sm text-red-600 dark:text-red-500'
                }
              >
                {errors.c_role?.message}
              </div>
            )}
          </div>
          {metric.type === 'c_pause-exceed' && (
            <div className="flex items-center justify-between">
              <span className="text-0color max-w-[222px] text-[15px]">{t('threshold')}</span>
              <div className="w-[180px] mr-[101px]">
                <Input
                  {...register('threshold', {
                    required: { value: true, message: tf('required') },
                  })}
                  name="threshold"
                  defaultValue={watch('threshold') || '0'}
                  onChange={(e) => setValue('threshold', Number(e.target.value))}
                  type={'number'}
                  icon={'ClockIcon'}
                  error={errors.name?.message}
                />
              </div>
            </div>
          )}
          {dataFields.map((e) => {
            return e.inputNumberChat.map((e, index) => {
              return (
                <div key={e.id} className="flex items-center justify-between">
                  <span className="text-0color max-w-[200px] text-[15px]">{e.labelTitle}</span>
                  <div className="w-[180px] mr-[101px]">
                    <Input
                      {...register(
                        e.id as
                          | 'exclude_begin_messages'
                          | 'exclude_end_messages'
                          | 'search_begin_messages'
                          | 'search_end_messages',
                      )}
                      max={1000000}
                      min={0}
                      name={e.id}
                      key={index}
                      type={'number'}
                    />
                  </div>
                </div>
              );
            });
          })}
          {editModal && (
            <div className="flex items-center justify-between">
              <span className="text-0color text-[15px]">{t('metric_id')}</span>
              <div className="flex gap-1">
                <p>
                  {metricTemplate
                    ? metricTemplateSettings?.metric_template_id
                    : metricSettings?.metric_id}
                </p>
                <TooltipWrapper content={t('popup_hints.copy_id')} id={'spech_metrick_copy'}>
                  <CircleButton
                    onClick={copyToClipboard}
                    icon="CopyIcon"
                    className="text-action mb-[5px]"
                    size={16}
                  />
                </TooltipWrapper>
              </div>
            </div>
          )}
          <div className="sticky bottom-0">
            <div className="bg-[#fff]">
              <div className="flex items-center justify-between py-4">
                <div className="gap-[15px] flex">
                  <Button
                    disabled={(page !== 'metricTemplate' && disableFunc()) || viewOnly}
                    type="submit"
                    label={t('save')}
                  />
                  <Button onClick={() => resetAsyncForm()} fill="linked" label={t('cancel')} />
                </div>
                <div>
                  {page !== 'metricTemplate' && !createFromTemplate && (
                    <Button
                      onClick={() => setCreateTemplateModal(true)}
                      fill="linked"
                      icon="FolderConfigIcon"
                      label={t('save_as_template')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <AddToTemplateModal
        show={createTemplateModal}
        name={watch('name')}
        applyBtn={createMetricTemplateApplyBtn}
        onClose={setCreateTemplateModal}
      />
    </>
  );
};
