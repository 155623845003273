import React, { FC, useRef, useState } from 'react';
import {
  dropAreaClassName,
  withoutDropAreaClassName,
} from '@app/components/ui/upload/upload.styles';
import Icon from '@app/components/ui/icons';
import { v4 } from 'uuid';
import useTranslation from '@app/hooks/use-translation';

type UploadPropsType = {
  fileAccept?: string;
  onChange?(files: File[]): void;
  multiple?: boolean;
  rtkBlock?: boolean;
  loadedFiles: File[];
  changeLoadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  useWithoutDropAreaStyle?: boolean;
};
const Upload: FC<UploadPropsType> = (props) => {
  const { t } = useTranslation('components.upload');
  const { t: ts } = useTranslation('pages.settings');
  const {
    fileAccept,
    onChange,
    loadedFiles,
    changeLoadedFiles,
    useWithoutDropAreaStyle,
    multiple = true,
    rtkBlock = false,
  } = props;
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (dragEvent: React.DragEvent<HTMLElement>) => {
    dragEvent.preventDefault();
    dragEvent.stopPropagation();

    if (dragEvent.type === 'dragenter' || dragEvent.type === 'dragover') {
      setDragActive(true);
    } else if (dragEvent.type === 'dragleave') {
      setDragActive(false);
    }
  };
  function checkAndRemoveWhitespaces(files: File[]) {
    return files.map((file) => {
      file = new File([file], file.name.replace(/\s/g, ''), { type: file.type });
      return file;
    });
  }

  // triggers when file is dropped
  const handleDrop = (dragEvent: React.DragEvent<HTMLDivElement>) => {
    dragEvent.preventDefault();
    dragEvent.stopPropagation();
    setDragActive(false);
    const filesFromDrop = dragEvent.dataTransfer.files;
    //if (fileAccept) {
    //  console.log('fileAccept', fileAccept);
    //  const extension = `.${dragEvent.dataTransfer.files[0].name.split('.').pop()}`;
    //  if (!fileAccept?.split(',').includes(extension)) return;
    //}
    if (filesFromDrop) {
      const files = multiple
        ? [...loadedFiles, ...Object.values(filesFromDrop)]
        : Object.values(filesFromDrop);
      const formattedFiles = checkAndRemoveWhitespaces(files);
      changeLoadedFiles(formattedFiles);
      handleFiles(formattedFiles);
    }
  };
  console.log(loadedFiles);

  const handleChange = (inputChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
    inputChangeEvent.preventDefault();
    if (inputChangeEvent.target.files) {
      const files = multiple
        ? [...loadedFiles, ...Object.values(inputChangeEvent.target.files)]
        : Object.values(inputChangeEvent.target.files);
      const formattedFiles = checkAndRemoveWhitespaces(files);
      changeLoadedFiles(formattedFiles);
      handleFiles(formattedFiles);
    }
  };
  const handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
  };

  function handleFiles(files: File[]) {
    // const formData = new FormData();
    // for (const file of files) {
    //   formData.append('files', file);
    // }
    onChange?.(files);
  }

  function deleteFile(index: number) {
    const filteredArr = loadedFiles.filter((_, ind) => ind !== index);
    changeLoadedFiles(filteredArr);
    handleFiles(filteredArr);
  }
  const onButtonClick = () => {
    inputRef.current?.click();
  };
  return (
    <>
      <form className="relative w-full" onSubmit={(e) => e.preventDefault()}>
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          multiple={multiple}
          onChange={handleChange}
          accept={fileAccept}
        />
        {rtkBlock ? (
          <div className="py-[15px] text-center text-basic_red text-[15px] font-[500]">
            {ts('rtk_block_description')}
          </div>
        ) : (
          <div
            className={
              useWithoutDropAreaStyle ? withoutDropAreaClassName() : dropAreaClassName(dragActive)
            }
            onDrop={handleDrop}
            onDragEnter={handleDrag}
            onDragOver={handleDragOver}
            onDragLeave={handleDrag}
          >
            {useWithoutDropAreaStyle ? (
              <span
                onClick={onButtonClick}
                className="hover:text-action underline cursor-pointer text-[16px] text-3color"
              >
                {t('upload_csv')}
              </span>
            ) : (
              <div className="text-[13px] text-3color">
                {t('info')}&nbsp;
                <span
                  onClick={onButtonClick}
                  className="hover:text-action underline cursor-pointer"
                >
                  {t('info_button')}
                </span>
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col gap-[5px] flex-wrap max-h-[90px]">
          {!!loadedFiles.length &&
            loadedFiles.map((file, index) => (
              <div key={v4()} className="flex items-center gap-[10px] h-full">
                <div className="max-w-[120px] text-[14px] text-4color flex gap-[2px] items-center">
                  <div
                    className={`truncate ${
                      (file.name.split('.').shift()?.length || 0) > 64 ? 'text-basic_red' : ''
                    }`}
                  >
                    {file.name.split('.').shift()}
                  </div>
                  <div
                    className={`${
                      (file.name.split('.').shift()?.length || 0) > 64 ? 'text-basic_red' : ''
                    }`}
                  >
                    {'.' + file.name.split('.').pop()}
                  </div>
                </div>
                <div onClick={() => deleteFile(index)} className="cursor-pointer ">
                  <Icon className="text-basic_red" size={11} name="TrashIcon" />
                </div>
                {(file.name.split('.').shift()?.length || 0) > 64 && (
                  <div className="text-[11px] text-basic_red font-[400]">
                    {t('max_length_error')}
                  </div>
                )}
              </div>
            ))}
        </div>
      </form>
    </>
  );
};

export default Upload;
