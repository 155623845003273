import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import useTranslation from '@app/hooks/use-translation';
import { GridIcon } from '@icons';
import { Checkbox, EasyModal, Loading } from '@ui';

import {
  useChangeChatCustomAliasesListMutation,
  useGetChatCustomAliasesListQuery,
} from '@app/store/api/custom-aliases.api';
import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import { useAppSelector } from '@app/store/store';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import { useLazyGetChatsQuery } from '@app/store/api/chat-api/all-chats.api';

export interface ChangeColumnDisplayModalProps {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  recordsParams: GetFilterParamsNew;
}
const ALL_RECORDS_FAKE_UUID = '722078c7-f8ee-4568-b7af-f92ad6e8632c';

export const ChatChangeColumnDisplayModalBlock: FC<ChangeColumnDisplayModalProps> = ({
  value,
  setValue,
  recordsParams,
}) => {
  const [selectedAliases, setSelectedAliases] = useState<string[]>([]);
  const { t } = useTranslation('pages.allRecords.change_column_display_modal');
  //api
  const { data, isLoading: loadingAliasesSettings } = useGetChatCustomAliasesListQuery();
  const [changeCustomAliasesList] = useChangeChatCustomAliasesListMutation();
  const [refetchRecordsData] = useLazyGetChatsQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const { userSettings, isLoading: loadingUserSettings } = useAppSelector(
    (state) => state.userSettings,
  );

  // useEffect
  useEffect(() => {
    if (!data?.show_aliases) return;
    setSelectedAliases(
      data?.show_aliases.filter((item) => item.checked).map((item) => item.alias_id),
    );
  }, [data?.show_aliases]);

  const saveCustomAliases = () => {
    const currentTableSettingsLayout = userSettings?.tableColsSize?.allChats?.[0]?.layout;
    changeCustomAliasesList({ aliases: selectedAliases }).then(() => {
      refetchRecordsData(recordsParams)
        .unwrap()
        .then(() => {
          const sysArr: ChartsLayoutType[] = selectedAliases.map((alias, index) => ({
            i: alias,
            w: currentTableSettingsLayout?.find((layo) => layo.i === alias)?.w || 6,
            h: currentTableSettingsLayout?.find((layo) => layo.i === alias)?.h || 1,
            y: currentTableSettingsLayout?.find((layo) => layo.i === alias)?.y || 0,
            x:
              currentTableSettingsLayout?.find((layo) => layo.i === alias)?.x ||
              Math.max(...(currentTableSettingsLayout?.map((layo) => layo.x) || [1])) + index,
            maxH: 1,
            maxW: 24,
          }));
          updateUserSettings({
            ...userSettings,
            tableColsSize: {
              ...userSettings?.tableColsSize,
              allChats: [{ id: ALL_RECORDS_FAKE_UUID, layout: sysArr }],
            },
          });
        });
      handleOnClose();
    });
  };
  function handleOnClose() {
    setValue(false);
  }
  if (loadingAliasesSettings || loadingUserSettings) return <Loading />;
  return (
    <EasyModal
      show={value}
      isAutoClosable={false}
      onClose={handleOnClose}
      onSuccess={saveCustomAliases.bind(null)}
      label={t('modal_label')}
    >
      <>
        <p className="pb-6 pt-4">{t('choose_cols')}</p>
        <div className="h-full mb-1">
          <table className="relative w-full bg-defaultBg rounded-defaultR border-separate border-spacing-y-1 px-1">
            <thead>
              <tr>
                <td className="bg-defaultBg uppercase text-xm font-semibold p-4">
                  {t('modal_description')}
                </td>
              </tr>
            </thead>
            <tbody>
              {data?.show_aliases.map((alias) => (
                <tr key={alias.alias_id}>
                  <td className="bg-white p-3.5 rounded-lg">
                    <div className="flex items-center gap-[10px]">
                      <div className="flex items-center mr-[10.06px]">
                        <GridIcon size={8} />
                      </div>
                      <Checkbox
                        checked={selectedAliases.includes(alias.alias_id)}
                        onChange={() =>
                          setSelectedAliases((prev) =>
                            prev.includes(alias.alias_id)
                              ? prev.filter((item) => item !== alias.alias_id)
                              : [...prev, alias.alias_id],
                          )
                        }
                      />
                      {alias.name}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </EasyModal>
  );
};
