module.exports = {
  presetFillChoose: {
    title: 'Условие отбора записей',
    description: 'Вы можете создать новый пресет или использовать шаблон',
    new_preset: 'Новый пресет',
    from_template: 'Из шаблона',
    create_preset_button: 'Создать пресет'
  },
  system: {
    create_as_template_name: 'Введите название шаблона',
  },
  filter: {
    title: 'Условия отбора записей в аналитический проект',
    buttons: {
      save: 'Сохранить',
      save_as_template: 'Сохранить как шаблон пресета',
      cancel: 'Отменить',
      delete: 'Удалить'
    }
  },
  templatesModal: {
    title: 'Выбрать шаблон пресета',
    empty_templates_list: 'Нет шаблонов'
  },
  table: {
    calls:  'звонков',
    first_column_title: 'Длительность',
    last_column_title: 'Действия',
  },
  popup_hints: {
    download_record_button: 'Скачать запись',
    to_record_title_button: 'Перейти к просмотру записи'
  },
};
