import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { Route } from 'react-router-dom';
import { BreadcrumbsItem } from '@app/layouts/layout';
import { QmStatisticOutlet } from '@app/pages/qm-module';

export const qmStatisticRoute = (
  <Route
    path={navigationRoutes.qm_module_statistic}
    handle={{
      crumb: () => (
        <BreadcrumbsItem path={navigationRoutes.qm_module_statistic} crumb="qm_statistic" />
      ),
    }}
    element={<QmStatisticOutlet />}
  ></Route>
);
