import React, { useState } from 'react';
import { Button, Loading, Upload } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { MetadataFillBlock } from '@app/pages/settings/blocks/manual-recording-upload/metadata-fill.block';
import { useLazyManualUploadRecordQuery } from '@app/store/api/menual-recording-upload';
import { InputFieldType } from '@app/pages/settings/blocks/manual-recording-upload/metadata-modal-block';
import { toast } from 'react-toastify';

type ManualRecordingUploadPageProps = {
  onCloseModal: () => void;
  rtkBlock?: boolean;
};

const ManualRecordingUploadPage: React.FC<ManualRecordingUploadPageProps> = ({
  onCloseModal,
  rtkBlock,
}) => {
  const { t } = useTranslation('pages.settings.manual_upload');
  const [manualUploadRecord] = useLazyManualUploadRecordQuery();
  const [csvFile, changeCsvFile] = useState<File[]>([]);
  const [audioFiles, changeAudioFiles] = useState<File[]>([]);
  const [inputFieldsData, setInputFieldsData] = useState<InputFieldType[]>([]);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function handleUploadAudioFile(files: File[]) {
    changeAudioFiles(files);
  }
  function handleUploadCsvFile(files: File[]) {
    changeCsvFile(files);
  }
  function resetForm() {
    changeAudioFiles([]);
    changeCsvFile([]);
  }
  const handleValidationErrors = (hasErrors) => {
    setIsValidationError(hasErrors);
  };
  const handleInputFieldsChange = (data) => {
    setInputFieldsData(data);
  };
  async function sendAudioHandle() {
    if (!audioFiles.length) return;
    setIsLoading(true);

    const recordMeta = inputFieldsData.reduce((acc, field) => {
      acc[field.name] = field.userInput;
      return acc;
    }, {});
    const metadata = JSON.stringify({ recordMeta });

    for (let i = 0; i < audioFiles.length; i++) {
      const file = audioFiles[i];
      const formData = new FormData();
      formData.append('audio', file);
      formData.append('metadata', metadata);
      if (csvFile && csvFile.length) {
        formData.append('file_metadata', csvFile[0]);
      }

      try {
        await manualUploadRecord(formData).unwrap();
      } catch (error) {
        toast.error(`${t('toast_error_upload')} ${i + 1}`);
        setIsLoading(false);
        return;
      }
    }

    toast.success(t('toast_success'));
    resetForm();
    setInputFieldsData([]);
    onCloseModal();
    setIsLoading(false);
  }

  return (
    <>
      {isLoading && (
        <div className="min-h-[]">
          <Loading />
        </div>
      )}
      <div className="relative h-full">
        <div className={`${isLoading ? '' : ''}`}>
          <div className="flex items-center w-full  py-[30px]">
            <h1 className="text-[18px] font-[700] ml-[20px]">{t('title')}</h1>
          </div>
          <div className="mx-[20px]">
            <Upload
              loadedFiles={audioFiles}
              changeLoadedFiles={changeAudioFiles}
              multiple={true}
              fileAccept="audio/*"
              onChange={handleUploadAudioFile}
              rtkBlock={rtkBlock}
            />
          </div>
          <div className="w-full flex justify-center">
            <div className="max-w-[1074px]">
              <MetadataFillBlock
                csvFiles={csvFile}
                changeCsvFiles={changeCsvFile}
                changeCsvFile={handleUploadCsvFile}
                show={!!audioFiles.length}
                onInputFieldsChange={handleInputFieldsChange}
                onValidationErrors={handleValidationErrors}
              />
            </div>
          </div>
          <div
            className={` transition-all delay-100 easy-in-out flex items-start justify-center w-full gap-[15px] py-4 ${
              !audioFiles.length ? 'invisible' : 'visible'
            }`}
          >
            <Button
              onClick={sendAudioHandle}
              label={t('save_button')}
              disabled={isValidationError || isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualRecordingUploadPage;
