import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { CustomAliasItem } from '@app/interfaces/aliases';

import { baseQuery } from '../baseQuery';
export const customAliasesApi = createApi({
  reducerPath: 'customAliasesApi',
  tagTypes: ['customAliases'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getCustomAliasesList: build.query<{ show_aliases: CustomAliasItem[] }, void>({
      query: () => ({
        url: serverRoutes.aliases,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.show_aliases.map(({ alias_id }) => ({
                type: 'customAliases' as const,
                alias_id,
              })),
              'customAliases',
            ]
          : ['customAliases'],
    }),
    changeCustomAliasesList: build.mutation<CustomAliasItem[], { aliases: string[] }>({
      query: (body) => ({
        url: serverRoutes.aliases,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['customAliases'],
    }),
    getChatCustomAliasesList: build.query<{ show_aliases: CustomAliasItem[] }, void>({
      query: () => ({
        url: serverRoutes.c_aliases,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.show_aliases.map(({ alias_id }) => ({
                type: 'customAliases' as const,
                alias_id,
              })),
              'customAliases',
            ]
          : ['customAliases'],
    }),
    changeChatCustomAliasesList: build.mutation<CustomAliasItem[], { aliases: string[] }>({
      query: (body) => ({
        url: serverRoutes.c_aliases,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['customAliases'],
    }),
  }),
});

export const {
  useGetCustomAliasesListQuery,
  useChangeCustomAliasesListMutation,
  useChangeChatCustomAliasesListMutation,
  useGetChatCustomAliasesListQuery,
} = customAliasesApi;
