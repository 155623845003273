import React, { FC } from 'react';

import { MetricHeaderItem, MetricMetricItemData } from '@app/interfaces/metric.type';
import { SettingsMetricType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { getMetricScoreStyles, getMetricScoreStyles100 } from './metric-table-value-build.styles';
import MetricEditValue from './metric-edit-value';

type MetricTableValueBuildProps = {
  metric: MetricMetricItemData;
  recordID: string;
  projectID: string;
  columnWidth?: number;
  metricHeader: MetricHeaderItem;
  index?: number;
  limit?: number;
  page?: number;
  currentTemplate: 'chat' | 'voice';
};

const SCORE_FIND_VISUALIZATION = 'score';
const TAGS_METRIC_TYPE = 'tags';

const MetricTableValueBuild: FC<MetricTableValueBuildProps> = React.memo((props) => {
  const {
    recordID,
    currentTemplate,
    projectID,
    page,
    index,
    metricHeader,
    metric: { value: metricValue, metric_id },
    columnWidth,
    limit,
  } = props;
  const visualization = metricHeader?.visualization;

  const renderScoreVisualization = {
    '5': {
      style: {},
      className: `${getMetricScoreStyles(
        Number(metricValue),
      )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`,
    },
    '100': {
      style: { width: `${30 + Math.ceil(Number(metricValue)) * 1.5}px` },
      className: `${getMetricScoreStyles100(
        Number(metricValue),
      )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`,
    },
  };
  if (visualization && RegExp(SCORE_FIND_VISUALIZATION).test(visualization)) {
    if (metricValue === null) return <></>;
    return (
      <div className="w-full flex items-center justify-start px-[10px]">
        <div
          className={renderScoreVisualization[visualization.split('score')[1]]?.className}
          style={renderScoreVisualization[visualization.split('score')[1]]?.style}
        >
          {metricValue || 0}
        </div>
      </div>
    );
  }
  return (
    <>
      {metricHeader?.type === TAGS_METRIC_TYPE && metricHeader?.settings ? (
        <MetricEditValue
          visualization={metricHeader.visualization as 'mix' | 'native' | 'icons'}
          settings={metricHeader?.settings as unknown as SettingsMetricType}
          project_id={projectID}
          metric_id={metric_id}
          record_id={recordID}
          currentValue={metricValue}
          valueType={metricHeader.result_value_type}
          columnWidth={columnWidth}
          index={index}
          currentTemplate={currentTemplate}
          limit={limit}
          page={page}
        />
      ) : (
        metricValue
      )}
    </>
  );
});
MetricTableValueBuild.displayName = 'MetricTableValueBuild';
export default MetricTableValueBuild;
