import { useTranslation } from '@app/hooks/use-translation';
import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import {
  useCreateProjectMetricGroupMutation,
  useEditProjectMetricGroupMutation,
} from '@app/store/api/metrics.api';
import { Button, Input, Select } from '@ui';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { nonFcrMetricTimeMap } from '../../data/data';

type TimeType = keyof typeof nonFcrMetricTimeMap;
type FormType = {
  name: string;
  metric1: string;
  time: TimeType;
  metric2: string;
};

const NonFcrMetric = ({ modalsSetState, metric, metricList, editModal, metricSettings }) => {
  const { t } = useTranslation('pages.metricTemplates');
  const { t: tf } = useTranslation('common.forms');
  const { id } = useParams();
  const [createMetric] = useCreateProjectMetricGroupMutation();
  const [editMetric] = useEditProjectMetricGroupMutation();
  const defaultValues: FormType = editModal
    ? {
        name: metricSettings?.name || '',
        metric1: metricSettings?.settings?.metric1?.metric_id || '',
        metric2: metricSettings?.settings?.metric2?.metric_id || 'default',
        time: (String(metricSettings?.settings?.before_h) as TimeType) || '1',
      }
    : { metric1: '', name: '', metric2: 'default', time: '1' };
  const {
    handleSubmit,
    watch,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: defaultValues,
  });

  const resetAsyncForm = () => {
    modalsSetState(metric.type);
  };

  const getSelectItems = () => {
    return (
      (metricList as MetricListArrType[]).map((e) => {
        return { title: e.name, value: e.metric_id };
      }) || []
    );
  };

  const timeSelectOptions = Object.entries(nonFcrMetricTimeMap).map(([key, value]) => ({
    title: value,
    value: key,
  }));

  function onSubmit(data: FormType) {
    const obj: MetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        metric1: { metric_id: data.metric1 },
        metric2: !data.metric2 || data.metric2 === 'default' ? null : { metric_id: data.metric2 },
        before_h: Number(data.time),
      },
    };
    if (!editModal) {
      id && createMetric({ params: { project_id: id }, body: obj });
    } else {
      if (id && metricSettings?.metric_id) {
        editMetric({
          params: { project_id: id, metric_id: metricSettings.metric_id },
          body: obj,
        });
      }
    }
    resetAsyncForm();
  }
  function valuesSetter(value: string, key: keyof FormType) {
    setValue(key, value);
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[550px]">
      <div className="flex flex-col gap-[20px]">
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{t('metric_name')}</span>
          <div className="w-[280px]">
            <Input
              {...register('name', { required: { value: true, message: tf('required') } })}
              error={errors.name?.message}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{t('abon_id')}</span>
          <div className="w-[280px]">
            <Controller
              render={() => (
                <Select
                  defaultValue={watch('metric1')}
                  onChange={(value) => valuesSetter(String(value), 'metric1')}
                  options={getSelectItems()}
                  error={errors.metric1?.message}
                />
              )}
              name="metric1"
              control={control}
              rules={{ validate: (value) => !!value || tf('required') }}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{t('time_period')}</span>
          <div className="w-[280px]">
            <Controller
              render={() => (
                <Select
                  defaultValue={watch('time')}
                  onChange={(value) => valuesSetter(String(value), 'time')}
                  options={timeSelectOptions}
                  error={errors.time?.message}
                />
              )}
              name="time"
              control={control}
              rules={{ validate: (value) => !!value || tf('required') }}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-0color text-[15px]">{t('theme')}</span>
          <div className="w-[280px]">
            <Select
              defaultValue={watch('metric2')}
              onChange={(value) => valuesSetter(String(value), 'metric2')}
              options={[
                {
                  title: t('not_apply'),
                  value: 'default',
                },
                ...getSelectItems(),
              ]}
            />
          </div>
        </div>
        <div className="sticky bottom-0">
          <div className="bg-[#fff]">
            <div className="flex items-center justify-between py-4">
              <div className="gap-[15px] flex">
                <Button label={t('save')} type="submit" />
                <Button onClick={() => resetAsyncForm()} fill="linked" label={t('cancel')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NonFcrMetric;
