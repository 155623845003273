import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CalendarIcon } from '@icons';
import { CircleButton, DropMenu, Modal, Paginator, PermissionAccessWrapper } from '@ui';
import { StatusLabel } from '@app/components/';
import { TableItem } from '@app/components/table/table.type';
import useTranslation from '@app/hooks/use-translation';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { StatusVariant } from '@app/components/status-label/status-label';
import { getFrontendDate } from '@app/utils/helpers';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { GroupSharedStatus, ProjectItem } from '@app/interfaces/analytics';
import { TableColumn } from '@app/components/rubber-table/table.types';
import { userPermissions } from '@app/utils/user-permissions';
import {
  useCopyLiveReportMutation,
  useDeleteLiveReportMutation,
  useGetAllLiveReportsListQuery,
} from '@app/store/api/live-reports.api';
import { LiveReport } from '@app/interfaces/report.type';

import { useAppSelector } from '@app/store/store';

import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import { downloadCsvReportChat, downloadCsvReportVoice } from '@app/api/downloadCsvFileReport';

import { toast } from 'react-toastify';

import RubberTable from '@app/components/rubber-table';

import fileDownload from 'js-file-download';

import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';

import { usePermissionCheck } from '@app/hooks/use-permission';

import {
  useCopyChatLiveReportMutation,
  useDeleteChatLiveReportMutation,
  useGetAllChatLiveReportsListQuery,
} from '@app/store/api/chat-api/live-reports.api';

import { tableUtilIconsClassName } from '../../records/blocks/records-table/records-table.styles';
// import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';

import { reportStatusPropertiesData } from '../data/report-status-properties.data';

const DEFAULT_REPORT_OFFSET = 0;
const DEFAULT_REPORT_LIMIT = 10;

const ReportsTableBlock: FC<{
  currentProjectId?: string;
  projectAp?: ProjectItem | null;
  currentTemplate?: 'chat' | 'voice';
}> = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentProjectId, projectAp, currentTemplate } = props;
  const { t } = useTranslation('pages.reports');
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const pageLimitByCondition = projectAp
    ? userSettings?.tablesLimit?.analyticProject?.reports
    : userSettings?.tablesLimit?.reports?.allReports;
  const [reportsTableLimit, changeReportsTableLimit] = useState<number>(
    pageLimitByCondition || DEFAULT_REPORT_LIMIT,
  );
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [reportTableOffset, changeReportsTableOffset] = useState<number>(DEFAULT_REPORT_OFFSET);
  const [reportTableOrderBy, changeReportTableOrderBy] = useState<
    { [x: string]: 1 | -1 } | undefined
  >();
  const reportCanBeCloned = usePermissionCheck({
    tag:
      currentTemplate === 'chat'
        ? userPermissions.action.actionCloneChatLiveReport
        : userPermissions.action.actionCloneLiveReport,
    permissions: 'action',
  });
  const reportCanBeEdited = usePermissionCheck({
    tag:
      currentTemplate === 'chat'
        ? userPermissions.action.actionEditChatLiveReport
        : userPermissions.action.actionEditLiveReport,
    permissions: 'action',
  });
  useEffect(() => {
    changeReportsTableLimit(
      projectAp
        ? userSettings?.tablesLimit?.analyticProject?.reports || 10
        : userSettings?.tablesLimit?.reports?.allReports || 10,
    );
  }, [
    projectAp,
    userSettings?.tablesLimit?.analyticProject?.reports,
    userSettings?.tablesLimit?.reports?.allReports,
  ]);
  const tableSortKeyList = useMemo(
    () => ({
      dt_add: 'date',
    }),
    [],
  );
  const [selectedIdAndShowRemoveModal, selectIdAndShowRemoveModal] = useState<LiveReport | null>(
    null,
  );

  const project = useSearchParams()[0].get('project');
  const selectedAndSort = useCallback(() => {
    const projectIsSelected = currentProjectId
      ? { project_id: currentProjectId }
      : project
      ? { project_id: project }
      : {};
    const sort =
      reportTableOrderBy && Object.keys(reportTableOrderBy).length
        ? {
            sortBy: tableSortKeyList[Object.keys(reportTableOrderBy)[0]],
            sortDesc: 0 < Object.values(reportTableOrderBy)[0],
          }
        : {};
    return {
      projectIsSelected,
      sort,
    };
  }, [currentProjectId, project, reportTableOrderBy, tableSortKeyList]);

  const { projectIsSelected, sort } = selectedAndSort();
  // api
  const [cloneLiveReportVoice] = useCopyLiveReportMutation();
  const [cloneLiveReportChat] = useCopyChatLiveReportMutation();

  const cloneLiveReport = { chat: cloneLiveReportChat, voice: cloneLiveReportVoice };
  const chatsIsHas = !!usePermissionCheck({
    permissions: 'display',
    tag: userPermissions.display.displayChats,
  });
  const { data: voiceLiveReportsListData } = useGetAllLiveReportsListQuery({
    offset: reportTableOffset,
    limit: reportsTableLimit,
    ...sort,
    ...projectIsSelected,
  });
  const { data: chatLiveReportsListData } = useGetAllChatLiveReportsListQuery(
    {
      offset: reportTableOffset,
      limit: reportsTableLimit,
      ...sort,
      ...projectIsSelected,
    },
    { skip: chatsIsHas },
  );

  const liveReportsListData = {
    chat: chatLiveReportsListData,
    voice: voiceLiveReportsListData,
  };
  const [deleteLiveReportVoice] = useDeleteLiveReportMutation();
  const [deleteLiveReportChat] = useDeleteChatLiveReportMutation();
  const deleteLiveReport = { chat: deleteLiveReportChat, voice: deleteLiveReportVoice };

  const tableReportColumns: Array<TableColumn> = [
    {
      title: t('table.report_name'),
      index: 'name',
      className: 'font-[700] hover:text-active',
    },
    { title: t('table.analytic_project'), index: 'project_name' },
    {
      title: t('table.status'),
      index: 'status',
      maxWidth: 120,
      tag: userPermissions.display.displayRepStatusCol,
    },
    { title: t('table.date_created'), index: 'dt_add', filter: true, maxWidth: 210 },
    {
      title: t('table.actions'),
      index: 'actions',
      maxWidth: 100,
    },
    { title: '', index: 'utils', maxWidth: 150, divider: 'left', truncate: false },
  ];

  function cloneLiveReportHandler({
    live_report_id,
    name,
  }: {
    live_report_id: string;
    name: string;
  }) {
    cloneLiveReport[currentTemplate || 'voice']({
      live_report_id,
      name: name + ` ${t('system.copy')}`,
    })
      .unwrap()
      .then((newReport) => {
        navigate(
          `/${routeMainByTemplate[currentTemplate || 'voice']}/${newReport.live_report_id}/${
            navigationRoutes.settings
          }`,
          { state: { newReport: true } },
        );
      });
  }
  function disableDeleteButton(project) {
    if (project.project.owner) {
      return false;
    } else return project.project.shared !== GroupSharedStatus.EDIT;
  }
  // dropMenu
  const downloadDropMenu: DropMenuItem[] = [
    { title: t('system.download_as_csv'), key: 'csv' },
    { title: t('system.download_as_xls'), key: 'xls' },
  ];
  // dropMenu
  const downloadCsvReport = {
    chat: downloadCsvReportChat,
    voice: downloadCsvReportVoice,
  };
  function downloadCsv(live_report_id: string, name: string, format: 'csv' | 'xls') {
    downloadCsvReport[currentTemplate || 'voice'](live_report_id, format)
      .then((data) => {
        if (format === 'csv') {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // add here
          const blob = new Blob([bom, data.data], { type: 'octet/stream' }), // add bom
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `${name}.${format}`;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          fileDownload(data.data, `${name}.${format + 'x'}`);
        }
      })
      .catch(() => toast.error('downloadError'));
  }

  const routeMainByTemplate = {
    chat: navigationRoutes.chatReports,
    voice: navigationRoutes.reports,
  };
  const handleReportNavigateWithState = (link: string) => {
    navigate(link, { state: projectAp ? { fromAp: pathname } : {} });
  };
  const currentDataTable = liveReportsListData[currentTemplate || 'voice']?.live_reports.map(
    (report) => ({
      ...report,
      name: (
        <Link
          className="hover:text-action transition duration-75"
          data-id={report.name}
          to={`/${routeMainByTemplate[currentTemplate || 'voice']}/${report.live_report_id}`}
          state={projectAp ? { fromAp: pathname } : {}}
        >
          {report.name}
        </Link>
      ),
      project_name: report.project.project_name,
      dt_add: (
        <div className="inline-flex items-center">
          <CalendarIcon size={20} className="text-3color ml-[0px] mr-[9px]" />
          {getFrontendDate(report.dt_add, 'dd.MM.yyyy HH:mm')}
        </div>
      ),
      filter: '',
      status: (
        <PermissionAccessWrapper
          tag={userPermissions.display.displayRepStatusCol}
          permissions={'display'}
        >
          <div className="inline-flex items-center">
            <StatusLabel
              status={'LIFE' as StatusVariant}
              statusProperties={reportStatusPropertiesData}
              isDropLeft
            />
          </div>
        </PermissionAccessWrapper>
      ),
      actions: (
        <div className="inline-flex items-center">
          <CircleButton
            icon="ChartIcon"
            hintTitle={t('popup_hints.to_charts')}
            size={18}
            link={`/${routeMainByTemplate[currentTemplate || 'voice']}/${report.live_report_id}`}
            onClick={() =>
              handleReportNavigateWithState(
                `/${routeMainByTemplate[currentTemplate || 'voice']}/${report.live_report_id}`,
              )
            }
            className={tableUtilIconsClassName}
          />
          <CircleButton
            icon="TableIcon"
            hintTitle={t('popup_hints.to_records')}
            link={`/${routeMainByTemplate[currentTemplate || 'voice']}/${report.live_report_id}/${
              navigationRoutes.reportRecords
            }`}
            size={18}
            onClick={() =>
              handleReportNavigateWithState(
                `/${routeMainByTemplate[currentTemplate || 'voice']}/${report.live_report_id}/${
                  navigationRoutes.reportRecords
                }`,
              )
            }
            className={tableUtilIconsClassName}
          />
        </div>
      ),
      utils: (
        <div className="inline-flex items-center">
          <CircleButton
            hintTitle={t('popup_hints.settings_icon')}
            icon="SettingIcon"
            onClick={() =>
              handleReportNavigateWithState(
                `/${routeMainByTemplate[currentTemplate || 'voice']}/${report.live_report_id}/${
                  navigationRoutes.settings
                }`,
              )
            }
            size={20}
            className={tableUtilIconsClassName}
          />
          <DropMenu
            dropSize={200}
            onClick={(key) => downloadCsv(report.live_report_id, report.name, key as 'csv' | 'xls')}
            menu={downloadDropMenu}
          >
            <CircleButton
              hintTitle={t('popup_hints.download_report')}
              icon="DownloadCloudIcon"
              size={20}
              className={tableUtilIconsClassName}
            />
          </DropMenu>
          <CircleButton
            hintTitle={t('popup_hints.copy_report')}
            icon="CopyIcon"
            size={18}
            disable={(() => {
              if (!reportCanBeCloned) {
                return disableDeleteButton(report);
              } else return true;
            })()}
            className={tableUtilIconsClassName}
            onClick={() =>
              cloneLiveReportHandler({ live_report_id: report.live_report_id, name: report.name })
            }
          />
          <CircleButton
            hintTitle={t('popup_hints.delete_icon')}
            disable={(() => {
              if (!reportCanBeEdited) {
                return disableDeleteButton(report);
              } else return true;
            })()}
            icon="TrashIcon"
            onClick={selectIdAndShowRemoveModal.bind(null, report)}
            size={18}
            className="text-basic_red"
          />
        </div>
      ),
    }),
  );

  function handlePageChange({ offset, limit }: PaginationResponse) {
    if (reportsTableLimit !== limit) {
      changeReportsTableLimit(limit);
      projectAp
        ? updateUserSettings({
            ...userSettings,
            tablesLimit: {
              ...userSettings?.tablesLimit,
              analyticProject: { ...userSettings?.tablesLimit?.analyticProject, reports: limit },
            },
          })
        : updateUserSettings({
            ...userSettings,
            tablesLimit: {
              ...userSettings?.tablesLimit,
              reports: { ...userSettings?.tablesLimit?.reports, allReports: limit },
            },
          });
    } else if (reportTableOffset !== offset) changeReportsTableOffset(offset);
  }

  function handlerRemoveReport() {
    deleteLiveReport[currentTemplate || 'voice']({
      id: selectedIdAndShowRemoveModal?.live_report_id || '',
    });
    selectIdAndShowRemoveModal(null);
  }
  return (
    <div className="w-full">
      <div className={'flex flex-col gap-[20px]'}>
        <RubberTable
          onFilter={changeReportTableOrderBy}
          isEmpty={t('empty')}
          skeletons={currentDataTable?.length}
          columns={tableReportColumns}
          dataSource={currentDataTable as unknown as TableItem[]}
          truncate={true}
        />
        {liveReportsListData[currentTemplate || 'voice']?.live_reports.length ? (
          <Paginator
            page={Math.ceil(reportTableOffset / reportsTableLimit) + 1}
            visiblePages={1}
            count={liveReportsListData[currentTemplate || 'voice']?.total || 0}
            limit={reportsTableLimit}
            onChange={handlePageChange}
          />
        ) : null}
      </div>
      <Modal
        id="remove_report"
        title={
          <>
            {t('modal.remove_report')} <br /> "{selectedIdAndShowRemoveModal?.name}"?
          </>
        }
        value={!!selectedIdAndShowRemoveModal}
        setValue={selectIdAndShowRemoveModal.bind(null, null)}
        onApply={handlerRemoveReport}
        variant="removal"
        size="xs"
      >
        <div className="text-[14px] font-[500]">{t('modal.confirm_remove_report')}</div>
      </Modal>
    </div>
  );
};

export default ReportsTableBlock;
