import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const ClipWithPlus: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 57 57"
      >
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#2987CC"
          d="M25.192 32.373c.757.744 1.672 1.294 3.027 1.587a53 53 0 0 1 1.075-1.27c-1.307-.22-2.234-.708-2.98-1.44-2.196-2.21-2.184-5.372.025-7.569l3.98-3.967c2.222-2.21 5.37-2.234 7.556-.037 2.21 2.21 2.185 5.347-.024 7.556l-1.038 1.038c.635-.098 1.428-.11 2.05.024l.623-.634c2.32-2.686 2.185-6.446-.476-9.107-2.868-2.856-6.86-2.82-9.655-.012l-4.175 4.175c-2.796 2.795-2.845 6.8.012 9.656m1.477-6.91c1.843.013 3.052.525 3.992 1.465 1.037 1.05 1.55 2.307 1.526 3.552a5.4 5.4 0 0 1 1.513-1.464c-.305-1.148-.94-2.234-1.916-3.21-.904-.904-2.027-1.478-3.821-1.624zm-8.692 14.16c2.857 2.857 6.849 2.808 9.656.013l2.259-2.27a5.8 5.8 0 0 1 0-2.198c-.135.183-.293.354-.452.525l-2.783 2.77c-2.21 2.21-5.36 2.235-7.556.038-2.21-2.21-2.185-5.36.024-7.557l2.856-2.868a3.26 3.26 0 0 1-.17-1.941l-3.846 3.833c-2.807 2.807-2.844 6.8.012 9.656m19.166 2.003c3.295 0 6.066-2.747 6.066-6.067 0-3.333-2.734-6.067-6.066-6.067-3.32 0-6.067 2.746-6.067 6.067 0 3.344 2.746 6.067 6.067 6.067m0-2.21c-.403 0-.647-.268-.647-.66v-2.538h-2.552c-.378 0-.647-.257-.647-.66 0-.402.269-.646.647-.646h2.552v-2.54c0-.378.244-.659.647-.659.402 0 .659.281.659.66v2.539h2.539c.378 0 .647.244.647.647 0 .402-.269.659-.647.659H37.8v2.539c0 .39-.256.659-.658.659"
        ></path>
      </svg>
    </div>
  );
};

export default ClipWithPlus;
