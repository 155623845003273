import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { RtkBlockResponse } from '@app/interfaces/rtk-block.type';

import { baseQuery } from '../baseQuery';

export const rtkBlockApi = createApi({
  reducerPath: 'rtkBlockApi',
  tagTypes: [],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getRtkBlockStatus: build.query<RtkBlockResponse, void>({
      query: () => ({
        url: serverRoutes.rtk_block,
      }),
    }),
  }),
});

export const { useGetRtkBlockStatusQuery } = rtkBlockApi;
