export const metricIconsData = {
  meta: 'meta',
  summ_speech_duration: 'summspeechduration',
  summ_silent_duration: 'summsilentduration',
  interruptions: 'interruptions',
  max_pause_duration: 'maxpauseduration',
  avg_pause_duration: 'avgpauseduration',
  find_words: 'findwords',
  max_monolog_duration: 'maxmonologduration',
  speech_rate_duration: 'speechrateduration',
  calculated: 'calculated',
  pause_exceed: 'pauseexceed',
  max_conflict: 'maxconflict',
  avg_conflict: 'avgconflict',
  classifier: 'classifier',
  tags: 'tagsmetricicon',
  //chat
  c_pause_exceed: 'pauseexceed',
  c_longest_pause_duration: 'maxpauseduration',
  c_avg_pause_duration: 'avgpauseduration',
  c_find_words: 'findwords',
  c_num_words_message: 'maxmonologduration',
  c_first_answer_duration: 'firstanswerduration',
  c_characters: 'character',
  c_spell_check: 'spellcheck',
  nonfcr: 'ClipWithPlus',
};
export const nonFcrMetricTimeMap = {
  '1': '1 час',
  '3': '3 часа',
  '6': '6 часов',
  '24': '24 часа',
  '48': '48 часов',
  '72': '72 часа',
  '168': '1 неделя',
};
