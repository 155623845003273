import React from 'react';
import { Outlet } from 'react-router-dom';

const QmStatisticOutlet = () => {
  return (
    <div>
      statistic
      <Outlet />
    </div>
  );
};

export default QmStatisticOutlet;
