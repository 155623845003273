import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import {
  activeClassName,
  chartMessageIconClassName,
} from '@app/components/audio-build/audio-chat/audio-chat.types';
import Icon from '@app/components/ui/icons';
import { Dialog } from '@app/interfaces/records';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import { emotionToColorConverter } from '@app/constants/emotions';
import { VoiceEmotion } from '@app/interfaces/record-transcription';

type AudioChannelsChartSettings = { iconName: IconNameType; iconColor: string };

type AudioChatItemProps = {
  toggleEmotions: boolean;
  isDialogType: boolean;
  id: string;
  onClick: () => void;
  phrase: ReactNode;
  emotion: VoiceEmotion;
  active: boolean;
  isChat: boolean;
  channelId: string;
  channelConfig: Dialog['channel_config'];
  duration: { from: number; to: number };
};

const AudioChatItem: FC<AudioChatItemProps> = (props) => {
  const {
    id,
    emotion,
    channelId,
    phrase,
    channelConfig,
    active,
    onClick,
    duration,
    toggleEmotions = false,
    isDialogType,
    isChat,
  } = props;
  const theme = process.env.REACT_APP_THEME;

  const clientIconColor = theme === 'rtk' ? 'bg-[#FF5E2D]' : 'bg-3color';

  const ICONS_LIST: { [x: string]: AudioChannelsChartSettings } = {
    operator: {
      iconName: 'HeadphoneIcon',
      iconColor: 'bg-speech_analitics',
    },
    client: {
      iconName: 'ProfileIcon',
      iconColor: clientIconColor,
    },
    other: {
      iconName: 'HelpCircleIcon',
      iconColor: 'bg-3color',
    },
  };

  const messageDurationInMinute = () => {
    const time = duration.from / 1000;
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = Math.ceil(time - hours * 3600 - minutes * 60);
    const result = [
      hours, // minutes
      minutes,
      seconds, // seconds
    ];
    return (hours ? result : result.splice(1)).map((n) => ('00' + n).slice(-2)).join(':');
    // const roundedMinute = Math.floor(duration.from / 60000);
    // const roundedSeconds = `0${Math.floor(Number(duration.from - roundedMinute * 60000) / 1000)}`;
    // return `${Math.floor(roundedMinute)}:${roundedSeconds.slice(-2)}`;
  };

  return (
    <li id={id} className="mb-2 ml-6 mr-6 " onClick={onClick.bind(null)}>
      <div
        className={cn(
          isDialogType ? channelId !== '0' && 'justify-end' : 'justify-between',
          'flex w-full',
        )}
      >
        {!isChat && (
          <div className="block w-[0px] min-h-[10px]">
            <div
              className="block w-[5px] min-h-[20px] ml-[-90px] h-[100%] rounded-[6px]"
              style={{
                opacity: toggleEmotions ? 1 : 0,
                backgroundColor: emotionToColorConverter(emotion),
              }}
            />
          </div>
        )}
        <div
          className={cn(
            channelId !== '0' && 'flex-row-reverse',
            isDialogType
              ? 'flex w-[70%] gap-[10px]'
              : 'flex-1 block relative hover:bg-speech_analitics_light cursor-pointer hover:rounded-[6px]',
            ' hover:opacity-90',
          )}
        >
          <div
            className={cn(
              isDialogType ? 'flex flex-col items-center w-[40px] pt-[8px]' : 'absolute',
            )}
          >
            <div
              className={chartMessageIconClassName(
                ICONS_LIST[channelConfig?.icon]?.iconColor || 'bg-3color text-white',
                isDialogType,
              )}
            >
              {ICONS_LIST[channelConfig?.icon] ? (
                <Icon
                  name={ICONS_LIST[channelConfig?.icon]?.iconName || ICONS_LIST[0]?.iconName}
                  className="text-white"
                  size={14}
                />
              ) : (
                <>{channelId}</>
              )}
            </div>
            <div
              className={cn(
                !isDialogType &&
                  `absolute ${
                    messageDurationInMinute().length <= 5 ? 'left-[-75px]' : 'left-[-90px]'
                  } top-[8px]`,
                'text-[11px] font-normal text-3color',
              )}
            >
              {messageDurationInMinute()}
            </div>
          </div>
          <div className={activeClassName({ active, isChat: isDialogType })}>
            <div className="mt-[-5px]">
              <h3 className="flex items-center text-right text-[15px] font-bold text-gray-900">
                {channelConfig?.name}
              </h3>
              <p className={cn('mb-4', 'text-base', 'text-sm', 'font-[500]', 'text-0color')}>
                {phrase}
              </p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default AudioChatItem;
