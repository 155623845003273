module.exports = {
  popup_hints: {
    delete_preset_group_button: 'Удалить условие',
    templates_list_count: 'Количество шаблонов в группе',
    to_record_title: 'Перейти к просмотру записи',
    download_record: 'Скачать запись',
    settings_template_icon: 'Настроить шаблон ',
    delete_template_icon: 'Удалить шаблон',
    copy_template_icon: 'Копировать шаблон',
  },
  system: {
    write_template_name: 'Введите название шаблона',
    calls: 'звонков',
    additional_name_to_copy: 'копия',
  },
  filter: {
    title: 'Условия отбора фильтра',
      buttons: {
        save_as_preset: 'Сохранить как новый пресет',
        save: 'Сохранить',
        delete: 'Удалить',
        cancel: 'Отменить',
      }
  },
  preset_remove_modal: {
    remove_label: 'Удалить пресет',
    remove_description: 'Подтвердите удаление пресета',
  },
  preset_settings_table: {
    first_label: 'Длительность',
    last_label: 'Действия',
  },
  page_header_group: 'Группа',
  page_header_group_placeholder: 'Выбрать проект',
  cant_create_group: 'Невозможно создать группу без имени',
  template_name: 'Название шаблона',
  presets_templates: 'Шаблоны пресетов',
  create_preset_template: 'Создать шаблон пресета',
  group_name: 'Название группы',
  remove_presets_group_title: 'Предупреждение',
  new_group: 'Новая группа',
  confirm_remove_presets_group: 'Вы действительно хотите удалить данную группу?',
  all_preset_templates_table: {
    name: 'НАЗВАНИЕ',
    time: 'Временной интервал',
    desc: 'Описание',
    actions: 'Действия',
  },
  copy: 'копия',
  from: 'от',
  to: 'до',
  last_days: 'последних суток',
  warning: 'Предупреждение',
  confirm_group_delete: 'Вы действительно хотите удалить данный шаблон?',
};
