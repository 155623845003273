import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { Route } from 'react-router-dom';
import { BreadcrumbsItem } from '@app/layouts/layout';
import { QmAppealPage } from '@app/pages/qm-module';

export const qmAppelRoute = (
  <Route
    path={navigationRoutes.qm_module_appeal}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.qm_module_appeal} crumb="qm_appeal" />,
    }}
    element={<QmAppealPage />}
  />
);
